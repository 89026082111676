import { FC, useRef, useState } from 'react'
import {
  AvailabilityRoom,
  AvailabilityRoomTypeFilter,
} from 'src/core/Availability/domain/Availability.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { RoomTypeDropdown } from './RoomTypeDropdown'
import styles from './RoomTypeFilter.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { classNames } from 'src/ui/utils/classnames'
import { Skeleton, Text } from 'src/ui/components'
import { useMedia } from 'src/ui/hooks/useMedia'
import { DropdownFullScreen } from './DropdownFullScreen'

interface Props {
  onRoomTypeSelected: (roomcode: AvailabilityRoomTypeFilter) => void
  onAllSelected: () => void
  rooms: AvailabilityRoom[] | undefined
  selectedRoomType: AvailabilityRoom | undefined
  isLoading: boolean
}

export const RoomTypeFilter: FC<Props> = ({
  rooms,
  selectedRoomType,
  onRoomTypeSelected,
  onAllSelected,
  isLoading,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const { media } = useMedia()

  const handleOnOutsideDropdownClick = () => {
    setShowDropdown(false)
  }

  const handleClick = () => {
    setShowDropdown(prev => !prev)
  }

  const handleSelectRoomType = (room: AvailabilityRoom) => {
    onRoomTypeSelected(room.id)
    setShowDropdown(false)
  }

  const handleSelectAll = () => {
    onAllSelected()
    setShowDropdown(false)
  }

  const handleCloseFullScreen = () => {
    setShowDropdown(false)
  }

  return (
    <>
      <div className={styles.titleWrapper}>
        {isLoading ? (
          <>
            <Skeleton width={250} height={20} className="mb-xs" />
            <Skeleton width={80} height={20} />
          </>
        ) : (
          <>
            <Text as="p" fontStyle="s-500" color="dark">
              {trans('available-room_room-type-filter_title')}
            </Text>
            <button
              onClick={handleSelectAll}
              className={classNames(
                styles.clearFilter,
                isUndefined(selectedRoomType) && styles.isHidden,
              )}
            >
              {trans('available-room_room-type_clear-filter')}
            </button>
          </>
        )}
      </div>
      <div className={styles.container} data-testid="room-type-selector">
        {isLoading ? (
          <Skeleton width="100%" height={48} />
        ) : (
          <>
            <div
              className={classNames(
                styles.selectContainer,
                showDropdown && styles.isOpen,
              )}
            >
              <button
                ref={triggerDropdownRef}
                onClick={handleClick}
                className={styles.button}
              >
                <span className={styles.buttonText}>
                  {isDefined(selectedRoomType) && selectedRoomType.name}
                  {isUndefined(selectedRoomType) &&
                    trans('available-room_room-type-filter_all', {
                      totalRooms: rooms?.length,
                    })}
                </span>
                <Icon
                  icon={ChevronIcon}
                  size="l"
                  color="fill-neutral-04"
                  rotate={showDropdown ? 270 : 90}
                />
              </button>
              <RoomTypeDropdown
                rooms={rooms ?? []}
                show={
                  (media === 'laptop' || media === 'desktop') && showDropdown
                }
                onClickOutside={handleOnOutsideDropdownClick}
                onSelect={handleSelectRoomType}
                onSelectAll={handleSelectAll}
                triggerRef={triggerDropdownRef}
                selectedRoomType={selectedRoomType}
              />
            </div>
            <DropdownFullScreen
              rooms={rooms ?? []}
              show={(media === 'mobile' || media === 'tablet') && showDropdown}
              onSelect={handleSelectRoomType}
              onSelectAll={handleSelectAll}
              onCloseDropdown={handleCloseFullScreen}
              selectedRoomType={selectedRoomType}
            />
          </>
        )}
      </div>
    </>
  )
}
