import { FC } from 'react'

interface Props {
  color: string
}

export const CircleIcon: FC<Props> = ({ color }) => (
  <circle cx="12" cy="12" r="12" fill={color} />
)

export default CircleIcon
