import { FC } from 'react'
import styles from './NearbyHotels.module.scss'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { NearbyHotelCard } from './NearbyHotelCard'

interface Props {
  nearbyHotels: NearbyHotel[]
  onReserve: (hotelId: string) => void
}

export const NearbyHotels: FC<Props> = ({ nearbyHotels, onReserve }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <>
      <Text fontStyle="2xl-700" color="dark">
        {trans('availability-error_not-found_alternative-hotels_title')}
      </Text>
      <Text fontStyle="m-300" color="dark" className="mt-m">
        {trans('availability-error_not-found_alternative-hotels_subtitle')}
      </Text>
      <div className={styles.container}>
        {nearbyHotels.map(hotel => (
          <NearbyHotelCard
            onReserve={onReserve}
            key={hotel.id}
            id={hotel.id}
            bestPriceAverage={hotel.bestPriceAverage}
          />
        ))}
      </div>
    </>
  )
}
