import { FC, useState, useRef } from 'react'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'
import { GuestsDropdownContent } from './GuestDropdownContent'
import styles from './GuestsDropdown.module.scss'
import { GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { PeopleIcon } from 'src/ui/components/atoms/Icon/_icons/specs/PeopleIcon'

interface Props {
  adultsList: number[]
  childrenList: number[]
  childrenAges?: Array<Array<number>>
  maxGuests: number
  maxChildren: number
  maxAdults: number
  maxChildAge: number
  showChildren: boolean
  guestsTitle: string
  onSubmit: (guests: GuestsFormValues) => void
}

export const GuestsDropdown: FC<Props> = ({
  adultsList,
  childrenList,
  childrenAges,
  maxGuests,
  maxChildren,
  maxAdults,
  maxChildAge,
  showChildren,
  guestsTitle,
  onSubmit,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev)
  }

  const hideDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <div className={styles.container} data-testid="guests-dropdown">
      <button
        className={styles.button}
        onClick={toggleDropdown}
        ref={triggerDropdownRef}
      >
        <Flex alignItems="center" gap="s">
          <Icon icon={PeopleIcon} size="l" />
          <Text
            fontStyle="m-500"
            color="dark"
            className={styles.occupancyTitle}
          >
            {guestsTitle}
          </Text>
        </Flex>
      </button>
      <GuestsDropdownContent
        key={showDropdown ? 1 : 0}
        adultsList={adultsList}
        childrenList={childrenList}
        childrenAges={childrenAges}
        maxGuests={maxGuests}
        maxAdults={maxAdults}
        maxChildren={maxChildren}
        maxChildAge={maxChildAge}
        showChildren={showChildren}
        showDropdown={showDropdown}
        onHideDropdown={hideDropdown}
        triggerRef={triggerDropdownRef}
        onSubmit={onSubmit}
      />
    </div>
  )
}
