import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FC } from 'react'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { Pill } from '../Pill'
import { DatePickerForm } from 'src/ui/views/_components/DatePickerForm'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'

interface Props {
  datesMethods: UseFormReturn<DatesFormValues>
  focused: boolean
  hasError: boolean
  onOpen: () => void
  datesText: string
}

export const DatesPill: FC<Props> = ({
  datesMethods,
  datesText,
  onOpen,
  focused,
  hasError,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Pill
      focused={focused}
      hasError={hasError}
      onOpen={onOpen}
      expandedComponent={
        <>
          <Text fontStyle="l-700" color="dark">
            {trans('occupancy-form_dates_title')}
          </Text>
          <FormProvider {...datesMethods}>
            <form>
              <DatePickerForm />
            </form>
          </FormProvider>
        </>
      }
      collapsedComponent={
        <>
          <Text fontStyle="m-300" color="dark">
            {trans('occupancy-form_dates_title')}
          </Text>
          <Flex justifyContent="center" alignItems="center" gap="xs">
            <Text fontStyle="m-700" color="dark">
              {datesText}
            </Text>
            <Icon icon={ChevronIcon} size="l" rotate={90} />
          </Flex>
        </>
      }
    />
  )
}
