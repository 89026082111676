import { Media } from 'src/ui/styles/objects/Media'
import { Skeleton } from 'src/ui/components'
import { FC } from 'react'

import styles from './Header.module.scss'

export const HeaderSkeleton: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerInterior}>
        <Media mobile tablet>
          <div>
            <Skeleton textLineHeight="xl" width={140} />
          </div>
        </Media>

        <Media laptop desktop>
          <div>
            <Skeleton textLineHeight="3xl" width={200} />
          </div>
        </Media>
      </div>
    </div>
  )
}
