import styles from './FastBooking.module.scss'
import { FC } from 'react'

import { Media } from 'src/ui/styles/objects/Media'
import { Tag, Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { classNames } from 'src/ui/utils/classnames'

import { DatesDropdown } from './DatesDropdown'
import { GuestsDropdown } from './GuestsDropdown'
import { IconTextSkeleton } from 'src/ui/components/molecules/IconText/IconTextSkeleton'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useMedia } from 'src/ui/hooks/useMedia'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CouponsDropdown } from './CouponsDropdown'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import { Skeleton } from 'src/ui/components/atoms/Skeleton'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { getGuestsResumeTitle } from 'src/ui/i18n/getGuestsResumeTitle'
import { CancelIcon } from 'src/ui/components/atoms/Icon/_icons/general/CancelIcon'
import { CircleIcon } from 'src/ui/components/atoms/Icon/_icons/general/CircleIcon'
import { PeopleIcon } from 'src/ui/components/atoms/Icon/_icons/specs/PeopleIcon'
import { CalendarIcon } from 'src/ui/components/atoms/Icon/_icons/general/CalendarIcon'
import { CouponIcon } from 'src/ui/components/atoms/Icon/_icons/benefits/CouponIcon'
import { getTotalNights } from 'src/ui/components/molecules/DatePicker/getTotalNights'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'

interface Props {
  className?: string
  dates: CheckInCheckOut | undefined
  guests: {
    adults: number[] | undefined
    children: number[] | undefined
    childrenAges?: Array<Array<number>> | undefined
  }
  hotel: Hotel | undefined
  onDatesSubmit: (dates: CheckInCheckOut) => void
  onGuestsSubmit: (guests: GuestsFormValues) => void
  onCouponsSubmit: (coupon: CouponsFormValues | undefined) => void
  onOpenAllWithDatesExpanded: () => void
  onOpenAllWithOccupancyExpanded: () => void
  onOpenAllWithCouponsExpanded: () => void
  initialErrorOnDates: boolean
  onValidateCoupon: (couponId: string) => Promise<CouponValidationResult>
}

export const FastBooking: FC<Props> = ({
  className,
  hotel,
  dates,
  guests,
  onDatesSubmit,
  onGuestsSubmit,
  onCouponsSubmit,
  onOpenAllWithDatesExpanded,
  onOpenAllWithOccupancyExpanded,
  onOpenAllWithCouponsExpanded,
  initialErrorOnDates,
  onValidateCoupon,
}) => {
  const { trans } = useTrans(['common', 'new-reservation'])
  const { media } = useMedia()
  const { numberOfCoupons, availabilityCoupon } = useCoupons()

  const { checkIn, checkOut } = dates || {}
  const { children, childrenAges, adults } = guests
  const showChildren = !hotel?.isAdultsOnly ?? true

  const showDates =
    isDefined(checkIn) &&
    isDefined(checkOut) &&
    isDefined(hotel) &&
    isDefined(hotel.guestOptions)
  const showGuests =
    isDefined(adults) &&
    isDefined(children) &&
    isDefined(hotel) &&
    isDefined(hotel.guestOptions)
  const showGuestsDropdown =
    isDefined(hotel) && isDefined(hotel.guestOptions) && showGuests

  const getRequiredGuestsTitle = (adults: number[], children: number[]) => {
    const totalAdults = adults.reduce((prev, current) => prev + current, 0)
    const totalChildren = children.reduce((prev, current) => prev + current, 0)
    const totalGuests = totalAdults + totalChildren

    if (media !== 'mobile') {
      return trans(
        'new-reservation:occupancy-form_room_guests_resume-guests-and-rooms',
        {
          guests: totalGuests,
          rooms: adults.length,
        },
      )
    }

    return trans('new-reservation:occupancy-form_room_guests_resume-guests', {
      guests: totalGuests,
    })
  }

  const getRequiredGuestsTitleWithChildren = (
    adults: number[],
    children: number[],
  ) => {
    return getGuestsResumeTitle({
      adultsList: adults,
      childrenList: children,
      trans,
      showRooms: media !== 'mobile',
    })
  }

  const getCouponsButtonText = () => {
    if (!availabilityCoupon?.applies || isUndefined(numberOfCoupons)) {
      return trans('new-reservation:fastbooking_add-discount')
    }

    return availabilityCoupon.value
  }

  const getApplyingCouponUnlessPromotional = () => {
    if (availabilityCoupon?.isPromotional()) {
      return availabilityCoupon
    }

    if (availabilityCoupon?.applies) {
      return availabilityCoupon
    }

    return undefined
  }

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid="fast-booking"
      data-target-adults={adults}
      data-target-children={children}
      data-target-nights={getTotalNights(checkIn, checkOut)}
    >
      <Media mobile tablet>
        <div className={styles.summary}>
          {initialErrorOnDates && (
            <Icon
              size="l"
              color="support-error"
              icon={CancelIcon}
              testId="cancel-icon"
            />
          )}
          {showDates ? (
            <button
              onClick={onOpenAllWithDatesExpanded}
              className={styles.summaryButton}
            >
              <Text fontStyle="m-500" color="dark">
                {datesManager.formatDateRangeToLocaleWithoutYear({
                  checkIn,
                  checkOut,
                })}
              </Text>
            </button>
          ) : (
            <Skeleton textLineHeight="s" />
          )}
          <Icon icon={CircleIcon} size="3xs" color="fill-neutral-02" />
          {showGuests ? (
            <button
              onClick={onOpenAllWithOccupancyExpanded}
              className={styles.summaryButton}
            >
              <Text fontStyle="m-500" color="dark">
                {getRequiredGuestsTitle(adults, children)}
              </Text>
            </button>
          ) : (
            <Skeleton textLineHeight="s" width={200} />
          )}
        </div>
        <div className={styles.summary}>
          <Tag className={styles.discountTag}>
            <Icon icon={CouponIcon} size="m" />
            <button
              className={classNames(styles.discountButton, 'ml-xs')}
              onClick={onOpenAllWithCouponsExpanded}
            >
              <Text fontStyle="m-500" color="dark">
                {getCouponsButtonText()}
              </Text>
            </button>
          </Tag>
        </div>
      </Media>

      <Media laptop desktop>
        <div className={styles.summary}>
          {isDefined(dates) ? (
            <DatesDropdown
              dates={dates}
              onSubmit={onDatesSubmit}
              initialError={initialErrorOnDates}
            />
          ) : (
            <IconTextSkeleton
              gap="s"
              icon={CalendarIcon}
              iconSize="l"
              iconColor="icon-dark"
              textLineHeight="s"
              textWidth={360}
            />
          )}
          <div className={styles.separator} />
          {showGuestsDropdown ? (
            <GuestsDropdown
              adultsList={adults}
              childrenList={children}
              childrenAges={childrenAges}
              maxGuests={hotel.guestOptions.maxGuests}
              maxChildren={hotel.guestOptions.maxChildren}
              maxChildAge={hotel.guestOptions.maxChildAge}
              maxAdults={hotel.guestOptions.maxAdults}
              showChildren={showChildren}
              guestsTitle={getRequiredGuestsTitleWithChildren(adults, children)}
              onSubmit={onGuestsSubmit}
            />
          ) : (
            <IconTextSkeleton
              gap="s"
              icon={PeopleIcon}
              iconSize="l"
              iconColor="icon-dark"
              textLineHeight="s"
              textWidth={200}
            />
          )}
        </div>

        <CouponsDropdown
          onValidateCoupon={onValidateCoupon}
          numberOfCoupons={numberOfCoupons}
          coupon={getApplyingCouponUnlessPromotional()}
          onSubmit={onCouponsSubmit}
        />
      </Media>
    </div>
  )
}
