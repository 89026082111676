import { useModal } from 'src/ui/hooks/useModal'
import { DatesModal } from 'src/ui/views/_components/DatesModal'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useEditAvailabilityActions } from 'src/ui/hooks/useEditAvailabilityActions'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityError } from 'src/core/Availability/domain/AvailabilityError'

export const useDatesModal = () => {
  const { showModal, hideModal } = useModal(DatesModal)
  const { availabilityCriteria } = useAvailabilityCriteria()
  const { modifyDates } = useEditAvailabilityActions()
  const { error } = useAvailability()

  const showDatesModal = () => {
    if (isUndefined(availabilityCriteria)) {
      return
    }

    showModal({
      checkIn: availabilityCriteria.checkIn,
      checkOut: availabilityCriteria.checkOut,
      onSubmit: modifyDates,
      onClose: hideModal,
      initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
    })
  }

  return { showDatesModal }
}
