import { FC, FormEvent, useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { Text } from 'src/ui/components'

import { Dropdown } from 'src/ui/components/atoms/Dropdown'

import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useDatesRangeForm } from 'src/ui/hooks/useDatesRangeForm'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'

import { classNames } from 'src/ui/utils/classnames'

import styles from './DatesDropdown.module.scss'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { DatePickerForm } from 'src/ui/views/_components/DatePickerForm'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { CalendarIcon } from 'src/ui/components/atoms/Icon/_icons/general/CalendarIcon'
import { CancelIcon } from 'src/ui/components/atoms/Icon/_icons/general/CancelIcon'
import {
  isDefined,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  dates: CheckInCheckOut
  initialError: boolean
  onSubmit: (dates: CheckInCheckOut) => void
}

export const DatesDropdown: FC<Props> = ({ dates, onSubmit, initialError }) => {
  const { trans } = useTrans(['common', 'new-reservation'])
  const { checkIn, checkOut } = dates

  const {
    methods: providerMethods,
    validateFields,
    resetForm,
    setManualErrorMessage,
  } = useDatesRangeForm(dates)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)
  const calendarRef = useRef<{
    set?: (key: string, value: number) => void
  }>(null)

  const initialErrorMessage = trans('new-reservation:dates-modal_initial-error')

  useEffect(() => {
    if (!initialError) {
      return
    }

    setManualErrorMessage(initialErrorMessage)
  }, [initialError])

  const closeDropdown = () => {
    resetForm()
    setShowDropdown(false)
  }

  const toggleDropdown = () => {
    if (showDropdown) {
      resetForm()
    }

    if (initialError) {
      setManualErrorMessage(initialErrorMessage)
    }

    if (!isNull(calendarRef.current) && isDefined(calendarRef.current.set)) {
      calendarRef.current.set('month', checkIn.getMonth() + 1) //Los índices del mes van de 0 a 11
      calendarRef.current.set('year', checkIn.getFullYear())
    }

    setShowDropdown(prev => !prev)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    validateFields(onSubmit, () => setShowDropdown(false))
  }

  const longDateRange = datesManager.formatDateRangeToLocaleWithYearAndWeekDay(
    { checkIn, checkOut },
    'short',
  )

  const totalNights = datesManager.calculateNights(checkIn, checkOut)

  return (
    <div className={styles.container} data-testid="dates-dropdown">
      {/* ## TODO: Llegar a un consenso sobre los Button/Links */}
      <button
        className={styles.button}
        onClick={toggleDropdown}
        ref={triggerDropdownRef}
      >
        <Flex alignItems="center" gap="xs">
          <Flex alignItems="center" gap="s">
            {initialError ? (
              <Icon
                size="l"
                color="support-error"
                icon={CancelIcon}
                testId="cancel-icon"
              />
            ) : (
              <Icon icon={CalendarIcon} size="l" />
            )}
            <Text
              className={classNames(
                styles.fancyText,
                showDropdown && styles.fancyText_selected,
              )}
              fontStyle="m-500"
              color="dark"
            >
              {longDateRange}
            </Text>
          </Flex>
          <Text fontStyle="xs-300" color="mid">
            {trans('common:dates-dropdown_total-nights', { totalNights })}
          </Text>
        </Flex>
      </button>

      <Dropdown
        show={showDropdown}
        triggerRef={triggerDropdownRef}
        onClickOutside={closeDropdown}
      >
        <FormProvider {...providerMethods}>
          <form onSubmit={handleSubmit}>
            <DatePickerForm ref={calendarRef} />
          </form>
        </FormProvider>
      </Dropdown>
    </div>
  )
}
