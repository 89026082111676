import { FormProvider, UseFormReturn } from 'react-hook-form'
import styles from './GuestsPill.module.scss'
import { Text } from 'src/ui/components/atoms/Text'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Divider } from 'src/ui/components/atoms/Divider'
import { Button } from 'src/ui/components/atoms/Button'
import { Translate } from 'src/ui/i18n/Translate'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FC } from 'react'
import { AvailabilityGuestsFormData } from 'src/ui/hooks/useGuestsForm'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Pill } from '../Pill'
import { GuestsForm } from 'src/ui/views/_components/GuestsForm'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'

interface Props {
  focused: boolean
  onOpen: () => void
  hasError: boolean
  guestsMethods: UseFormReturn<AvailabilityGuestsFormData>
  uniqueIds: string[]
  maxGuests: number
  maxChildren: number
  maxAdults: number
  maxChildAge: number
  showChildren: boolean
  expandedRoomId: string
  onDeleteRoom: (uniqueId: string) => void
  onOpenRoom: (uniqueId: string) => void
  canAddMoreRooms: boolean
  onAddRoom: () => void
  guestsDraftTitle: string
  roomsNumber: number
}

export const OccupancyPill: FC<Props> = ({
  guestsMethods,
  onOpen,
  hasError,
  focused,
  uniqueIds,
  maxGuests,
  maxChildren,
  maxAdults,
  maxChildAge,
  showChildren,
  expandedRoomId,
  onOpenRoom,
  onDeleteRoom,
  canAddMoreRooms,
  onAddRoom,
  roomsNumber,
  guestsDraftTitle,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  return (
    <Pill
      focused={focused}
      onOpen={onOpen}
      hasError={hasError}
      expandedComponent={
        <FormProvider {...guestsMethods}>
          <form noValidate>
            <div className={styles.title}>
              <Text fontStyle="xl-700" color="dark">
                {trans('occupancy-form_guests_main-title')}
              </Text>
            </div>
            {uniqueIds.map((uniqueId: string, roomIndex: number) => (
              <div key={uniqueId}>
                <GuestsForm
                  maxGuests={maxGuests}
                  maxChildren={maxChildren}
                  maxAdults={maxAdults}
                  maxChildAge={maxChildAge}
                  showChildren={showChildren}
                  roomIndex={roomIndex}
                  roomUniqueId={uniqueId}
                  numberOfUniqueIds={uniqueIds.length}
                  expandedRoomId={expandedRoomId}
                  hasErrors={hasError}
                  onDeleteRoom={onDeleteRoom}
                  onOpenRoom={onOpenRoom}
                />
                {roomIndex < uniqueIds.length - 1 && (
                  <Divider dir="horizontal" />
                )}
              </div>
            ))}
            {canAddMoreRooms ? (
              <div className={styles.formButtonWrapper}>
                <Button
                  variant="secondary"
                  onClick={e => {
                    e.preventDefault()
                    onAddRoom()
                  }}
                >
                  {trans('occupancy-form_add-room')}
                </Button>
              </div>
            ) : (
              <Translate
                i18nKey="new-reservation:occupancy-form_guests_add-room_max-rooms"
                values={{
                  phone: `${callCenterPhone}${callCenterFreePhone}`,
                }}
                components={{
                  regular: (
                    <Text
                      className="mt-xl mb-l"
                      as="p"
                      fontStyle="m-300"
                      color="mid"
                    />
                  ),
                  bold: (
                    <Text
                      data-data-phone={callCenterCountryCode}
                      className={styles.phone}
                      fontStyle="link"
                      color="mid"
                    />
                  ),
                }}
              />
            )}
          </form>
        </FormProvider>
      }
      collapsedComponent={
        <>
          <Text fontStyle="m-300" color="dark">
            {trans('occupancy-form_guests_subtitle')}
          </Text>
          <Flex justifyContent="center" alignItems="center" gap="xs">
            <Flex direction="column" alignItems="flex-end">
              <Text fontStyle="m-700" color="dark">
                {guestsDraftTitle}
              </Text>
              <Text fontStyle="m-700" color="dark">
                {trans('occupancy-form_room_guests_resume-rooms', {
                  rooms: roomsNumber,
                })}
              </Text>
            </Flex>
            <Icon icon={ChevronIcon} size="l" rotate={90} />
          </Flex>
        </>
      }
    />
  )
}
