import { Alert } from 'src/core/Hotel/domain/Alert.model'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'

export const getHotelMarketingAlert =
  ({ hotelRepository, sessionStorageClient }: Dependencies) =>
  async (
    hotelId: string,
    checkIn: string,
    checkOut: string,
  ): Promise<Alert | undefined> => {
    const alreadyShowed = sessionStorageClient.get(
      'hotelMarketingAlertAlreadyShowed',
    )

    if (isDefined(alreadyShowed) && alreadyShowed) {
      return undefined
    }

    const alerts = await hotelRepository.marketingAlerts(
      hotelId,
      checkIn,
      checkOut,
    )

    if (isUndefined(alerts) || isEmpty(alerts)) {
      return undefined
    }

    return alerts[0]
  }

interface Dependencies {
  hotelRepository: HotelRepository
  sessionStorageClient: StorageClient
}
