import { Button, Divider, Link, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './AvailabilityError.module.scss'
import {
  AvailabilityError as AvailabilityErrorModel,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Translate } from 'src/ui/i18n/Translate'
import { NearbyHotels } from './NearbyHotels'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { NearbyHotelsSkeleton } from './NearbyHotels/NearbyHotelsSkeleton'

interface Props {
  error: AvailabilityErrorModel
  nearbyHotels: NearbyHotel[] | undefined
  areNearbyHotelsLoading: boolean
  onChangeDates: () => void
  onReserve: (hotelId: string) => void
}

export const AvailabilityError: FC<Props> = ({
  error,
  nearbyHotels,
  areNearbyHotelsLoading,
  onChangeDates,
  onReserve,
}) => {
  return error.type ===
    AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR ? (
    <NotFoundError
      onChangeDates={onChangeDates}
      onReserve={onReserve}
      nearbyHotels={nearbyHotels}
      areNearbyHotelsLoading={areNearbyHotelsLoading}
    />
  ) : (
    <GenericError />
  )
}

interface NotFoundErrorProps {
  areNearbyHotelsLoading: boolean
  nearbyHotels: NearbyHotel[] | undefined
  onChangeDates: () => void
  onReserve: (hotelId: string) => void
}

const NotFoundError: FC<NotFoundErrorProps> = ({
  areNearbyHotelsLoading,
  nearbyHotels,
  onChangeDates,
  onReserve,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  return (
    <div
      data-target-has-no-availability={true}
      data-quantum-warning="no availability"
    >
      <Flex direction="column">
        <Text
          fontStyle="2xl-700"
          color="dark"
          as="h2"
          className="mt-mobile-l mt-tablet-l mt-laptop-l mt-desktop-l"
        >
          {trans('availability-error_not-found_title')}
        </Text>
        <Text fontStyle="m-300" className="mt-m" color="dark">
          {trans('availability-error_not-found_subtitle')}
        </Text>
        <Button
          className="mt-xl mb-l"
          onClick={onChangeDates}
          size={{
            tablet: 'fullWidth',
            mobile: 'fullWidth',
            laptop: 'small',
            desktop: 'large',
          }}
        >
          {trans('availability-error_not-found_change-dates')}
        </Button>
        <>
          <Divider
            dir="horizontal"
            className="mb-xl mt-mobile-2xl mt-tablet-2xl mt-laptop-xl mt-desktop-3xl"
          />
          {areNearbyHotelsLoading ? (
            <NearbyHotelsSkeleton />
          ) : (
            isDefined(nearbyHotels) && (
              <NearbyHotels onReserve={onReserve} nearbyHotels={nearbyHotels} />
            )
          )}
        </>
        <Text fontStyle="m-300" color="dark">
          {trans('availability-error_not-found_if-need-help')}{' '}
          <Link
            data-data-phone={callCenterCountryCode}
            variant="secondary"
            link={`tel:${callCenterPhone}`}
          >
            {callCenterPhone}
          </Link>
          {callCenterFreePhone}
        </Text>
      </Flex>
    </div>
  )
}

const GenericError = () => {
  const { trans } = useTrans(['new-reservation'])
  const { reload } = useApplicationRouter()

  return (
    <div data-quantum-error="availability generic error">
      <Text fontStyle="2xl-700" color="dark" as="h2" className="mt-3xl">
        {trans('availability-error_generic_title')}
      </Text>
      <Text fontStyle="xs-500" color="mid" className="mt-xs" uppercase>
        {trans('availability-error_generic_subtitle')}
      </Text>
      <Flex direction="column" gap="m" className="mt-m">
        <Text fontStyle="m-300" color="dark">
          {trans('availability-error_generic_description')}
        </Text>
        <Text fontStyle="m-300" color="dark">
          <Translate
            i18nKey="new-reservation:availability-error_generic_help-call-or-reload"
            components={{
              callUs: <GenericErrorCallUs />,
              reload: (
                <button onClick={reload} className={styles.refreshButton}>
                  <Text
                    fontStyle="link"
                    color="dark"
                    className={styles.refreshButtonText}
                  />
                </button>
              ),
            }}
          />
        </Text>
      </Flex>
    </div>
  )
}

const GenericErrorCallUs = () => {
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()
  return (
    <>
      <Link
        data-data-phone={callCenterCountryCode}
        variant="secondary"
        link={`tel:${callCenterPhone}`}
      >
        {callCenterPhone}
      </Link>
      {callCenterFreePhone}
    </>
  )
}
