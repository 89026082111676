import { FC, useState, useRef, FormEvent } from 'react'
import styles from './CouponsDropdown.module.scss'
import { Dropdown, Text, Button, Tag } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { classNames } from 'src/ui/utils/classnames'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Flex } from 'src/ui/styles/objects/Flex'
import { FormProvider } from 'react-hook-form'
import {
  useCouponsForm,
  CouponFormType,
  CouponsFormValues,
} from 'src/ui/hooks/useCouponsForm'
import { useUser } from 'src/ui/contexts/UserContext'
import { LevelCoupon } from './LevelCoupon'
import { CouponIcon } from 'src/ui/components/atoms/Icon/_icons/benefits/CouponIcon'
import { CouponsFormContent } from 'src/ui/views/_layouts/AvailableRooms/CouponsFormContent'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  className?: string
  numberOfCoupons?: number
  coupon: AvailabilityCoupon | undefined
  onSubmit: (coupon: CouponsFormValues | undefined) => void
  onValidateCoupon: (couponId: string) => Promise<CouponValidationResult>
}

export const CouponsDropdown: FC<Props> = ({
  className,
  numberOfCoupons,
  coupon,
  onSubmit,
  onValidateCoupon,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const triggerDropdownRef = useRef<HTMLButtonElement>(null)
  const { methods, areValuesSameAsOld, getCoupon } = useCouponsForm(coupon)
  const { user } = useUser()

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev)
    resetForm()
  }

  const handleClickOutside = () => {
    closeDropdown()
    resetForm()
  }

  const closeDropdown = () => {
    setShowDropdown(false)
  }

  const resetForm = () => {
    if (isUndefined(coupon)) {
      methods.reset({
        type: CouponFormType.PromotionalCode,
        value: '',
      })
    } else {
      methods.reset({
        type: couponTypeMap[coupon?.type],
        value: coupon?.value,
      })
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (areValuesSameAsOld()) {
      setShowDropdown(false)
      return
    }

    onSubmit(getCoupon())
    closeDropdown()
  }

  const getButtonText = () => {
    if (!coupon?.applies || isUndefined(numberOfCoupons)) {
      return trans('new-reservation:fastbooking_add-discount')
    }

    return coupon.value
  }

  const showLevelCoupon =
    isDefined(user) &&
    isDefined(user.discount) &&
    methods.getValues('type') !== CouponFormType.GroupCode

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid="coupons-dropdown"
    >
      <Tag className={styles.discountTag}>
        <ButtonLink onClick={toggleDropdown} ref={triggerDropdownRef}>
          <Flex gap="xs" alignItems="center">
            <Icon icon={CouponIcon} size="m" />
            <Text fontStyle="m-500">{getButtonText()}</Text>
          </Flex>
        </ButtonLink>
      </Tag>

      <Dropdown
        show={showDropdown}
        className={styles.dropdown}
        triggerRef={triggerDropdownRef}
        onClickOutside={handleClickOutside}
        autoFocus={false}
      >
        <Flex
          alignItems="baseline"
          justifyContent="space-between"
          className="mb-l"
        >
          <Text fontStyle="xl-700" color="dark">
            {trans('new-reservation:coupons-form_discounts')}
          </Text>
          <Text fontStyle="m-300" color="support-success">
            {trans('new-reservation:coupons-form_only-in-my-barcelo')}
          </Text>
        </Flex>

        {showLevelCoupon && <LevelCoupon user={user} />}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <CouponsFormContent
              methods={methods}
              onValidateCoupon={onValidateCoupon}
              revalidate={showDropdown}
              keyForValidPromotionalCodeMessage="coupons-input_validation_valid"
              keyForInvalidValidPromotionalCodeMessage="coupons-input_validation_invalid"
            />
            <div className={styles.submitButtonWrapper}>
              <Button
                size={{
                  mobile: 'fullWidth',
                  laptop: 'large',
                }}
              >
                {trans('new-reservation:coupons-form_submit')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Dropdown>
    </div>
  )
}

const couponTypeMap: { [key in AvailabilityCoupon['type']]: CouponFormType } = {
  group: CouponFormType.GroupCode,
  promotional: CouponFormType.PromotionalCode,
}
