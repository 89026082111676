import { FC } from 'react'
import styles from './Sanitizer.module.scss'
import * as DOMPurify from 'dompurify'

interface Props {
  htmlText: string
}

export const Sanitizer: FC<Props> = ({ htmlText }) => {
  const htmlSanitized = DOMPurify.sanitize(htmlText)

  return (
    <div
      className={styles.htmlSanitized}
      data-testid="htmlSanitized"
      dangerouslySetInnerHTML={{ __html: htmlSanitized }}
    />
  )
}
