import { FC, useState } from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRate,
  AvailabilityFlatRate,
  AvailabilityRoom,
  getRateFromRoom,
  howCouponAppliesInRate,
} from 'src/core/Availability/domain/Availability.model'
import {
  ReservedRate,
  SelectedRate,
} from 'src/core/Reservation/domain/Reservation.model'
import styles from './RateSelector.module.scss'
import { OnRateSelected } from '../../AvailableRooms.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MealplansFilter } from './MealplansFilter'
import { TypeRateSelector } from './TypeRateSelector'
import { CurrentRatePrice } from './CurrentRatePrice'
import { Button, Divider } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'
import { Flex } from 'src/ui/styles/objects/Flex'
import { RatePoliciesSelector } from './RatePoliciesSelector'
import { MealplanTag } from 'src/ui/views/AvailableRooms/Room/RateSelector/MealplanTag'
import { useMarket } from 'src/ui/contexts/MarketContext'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { classNames } from 'src/ui/utils/classnames'
import { getGuaranteeTitle } from 'src/ui/i18n/getGuaranteeTitle'
import { getCancellationTitle } from 'src/ui/i18n/getCancellationTitle'
import {
  getAvailableRatesFrom,
  getRatePoliciesWithRateId,
  RatePolicies,
} from './RateSelector.model'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'

interface Props {
  room: AvailabilityRoom
  reservedRate?: ReservedRate
  onRateSelected: OnRateSelected
  selectedRate: SelectedRate | undefined
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
}

export const RateSelector: FC<Props> = ({
  room,
  onRateSelected,
  totalNightsFromAvailability,
  isOneNight,
  selectedRate: previousSelectedRate,
  reservationInProgress,
  isMultiroom,
}) => {
  const { trans } = useTrans(['new-reservation', 'common'])
  const { promotionalCoupon } = useCoupons()
  const { queryUtils } = useApplicationRouter()
  const mealplanFromURL = queryUtils.getMealplanParam()
  const rateIdFromURL = queryUtils.getRateParam()
  const currentMealplanInCart = room.mealplans.find(mp => {
    return mp.id === previousSelectedRate?.mealplanId
  })
  const [selectedMealplan, setSelectedMealplan] =
    useState<AvailabilityMealplan>(getDefaultMealplan())
  const ratePolicies = room.mealplans.flatMap(mp => buildRatePoliciesFrom(mp))

  const currentRatePolicy = getRatePoliciesWithRateId(
    ratePolicies,
    previousSelectedRate?.id ?? rateIdFromURL,
  )

  const [selectedRatePolicies, setSelectedRatePolicies] =
    useState<RatePolicies>(
      currentRatePolicy || buildRatePoliciesFrom(selectedMealplan)[0],
    )

  const currentRate =
    isDefined(previousSelectedRate) && !isEmpty(previousSelectedRate)
      ? getRateFromRoom(room, previousSelectedRate.id)
      : getRateFromRoom(room, rateIdFromURL)

  const [selectedRate, setSelectedRate] = useState<AvailabilityFlatRate>(
    currentRate ?? selectedRatePolicies.rate,
  )

  const hasOnlyOneMealplan = room.mealplans.length === 1

  function getDefaultMealplan(): AvailabilityMealplan {
    if (isDefined(currentMealplanInCart)) {
      return currentMealplanInCart
    }

    if (isDefined(mealplanFromURL)) {
      const mealplan = room.mealplans.find(mp => mp.id === mealplanFromURL)
      if (isDefined(mealplan)) {
        return mealplan
      }
    }

    return room.mealplans[0]
  }

  function buildRatePoliciesFrom(
    mealplan: AvailabilityMealplan,
  ): RatePolicies[] {
    return mealplan.rates.map(mapRateToRatePolicies)
  }

  function mapRateToRatePolicies(rate: AvailabilityRate): RatePolicies {
    const guaranteeTitle = getGuaranteeTitle(
      rate.guarantee,
      'before-reservation',
      trans,
    )
    const cancellationTitle = getCancellationTitle(rate.cancellation, trans)
    const isCancellationHighlighted =
      rate.cancellation.cancellationType === 'free'

    return {
      id: rate.id,
      rate: rate,
      guarantee: guaranteeTitle,
      cancellation: cancellationTitle,
      isCancellationHighlighted,
      name: `G: ${rate.guarantee.type.key} - C: {applies: ${rate.cancellation.applies}, flexible: ${rate.cancellation.flexible}, penalty: ${rate.cancellation.relative.penalty.value} ${rate.cancellation.relative.penalty.type}}`,
    }
  }

  const { marketPrice } = useMarket()
  const isMarketWithNetPrice = marketPrice?.netPrices

  const howCouponApplies = () => {
    const baseRate = selectedRatePolicies.rate
    return howCouponAppliesInRate(baseRate, promotionalCoupon)
  }

  const selectableRates = getAvailableRatesFrom(selectedRatePolicies)

  return (
    <div className={styles.container}>
      {hasOnlyOneMealplan ? (
        <MealplanTag
          mealplan={selectedMealplan}
          className={styles.mealplanFilterContainer}
        />
      ) : (
        <MealplansFilter
          mealplans={room.mealplans}
          selectedMealplan={selectedMealplan}
          onMealplanSelected={(mealplan: AvailabilityMealplan) => {
            const ratesPolicies = buildRatePoliciesFrom(mealplan)

            setSelectedMealplan(mealplan)
            setSelectedRatePolicies(ratesPolicies[0])
            setSelectedRate(ratesPolicies[0].rate)
          }}
          className={styles.mealplanFilterContainer}
        />
      )}

      <Media laptop desktop>
        <Divider dir="horizontal" className={styles.divider} />
      </Media>

      <div className={styles.rateSelectorContainer}>
        <RatePoliciesSelector
          ratesPolicies={buildRatePoliciesFrom(selectedMealplan)}
          room={room}
          currentRatePolicies={selectedRatePolicies}
          onRatePoliciesSelected={ratePolicies => {
            setSelectedRatePolicies(ratePolicies)
            setSelectedRate(ratePolicies.rate)
          }}
        />

        <TypeRateSelector
          room={room}
          selectableRates={selectableRates}
          currentRate={selectedRate}
          onRateSelected={rate => setSelectedRate(rate)}
          howCouponApplies={howCouponApplies()}
          totalNightsFromAvailability={totalNightsFromAvailability}
        />
      </div>

      <Flex
        direction="column"
        alignItems="flex-end"
        data-testid={`reserve_container-${room.id}_${selectedRate.id}`}
        className={classNames(
          styles.currentPriceContainer,
          !isMultiroom && styles.isSingleRoom,
        )}
      >
        <CurrentRatePrice
          rate={selectedRate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies()}
        />
        <Button
          data-target-price={
            isMarketWithNetPrice
              ? Math.round(selectedRate.total.netPrice.value)
              : Math.round(selectedRate.total.grossPrice.value)
          }
          data-target-gross-price={Math.round(
            selectedRate.total.grossPrice.value,
          )}
          onClick={() =>
            onRateSelected(
              room.id,
              room.name,
              { id: selectedMealplan.id, name: selectedMealplan.name },
              selectedRate,
            )
          }
          isLoading={reservationInProgress}
          size={{ mobile: 'smallFullWidth', laptop: 'fullWidth' }}
          data-testid={`reserve_button-${room.id}_${selectedRate.id}`}
          data-rate-code={selectedRate.id}
        >
          {trans('available-rooms_select-rate')}
        </Button>
      </Flex>
    </div>
  )
}
