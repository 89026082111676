import styles from './MealplanTag.module.scss'
import { FC } from 'react'
import { Text } from 'src/ui/components'
import { AvailabilityMealplan } from 'src/core/Availability/domain/Availability.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { classNames } from 'src/ui/utils/classnames'
import { MealplansHeader } from '../MealplansHeader'

interface Props {
  mealplan: AvailabilityMealplan
  className: string
}

export const MealplanTag: FC<Props> = ({ mealplan, className }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <section className={classNames(styles.container, className)}>
      <MealplansHeader
        mealplans={[mealplan]}
        title={trans('available-rooms_mealplans-tag_title')}
      />
      <div className={styles.mealplanTagContainer}>
        <div className={styles.mealplanTag} data-testid={mealplan.name}>
          <Text fontStyle={{ mobile: 's-500', laptop: 'm-500' }} color="light">
            {mealplan.name}
          </Text>
        </div>
      </div>
    </section>
  )
}
