import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'

export const mapCouponParam = ({
  availabilityCoupon,
  promotionalCouponApplies,
}: {
  availabilityCoupon: AvailabilityCoupon | undefined
  promotionalCouponApplies?: boolean
}) => {
  if (isUndefined(availabilityCoupon) || !availabilityCoupon.applies) {
    return undefined
  }

  if (availabilityCoupon.isGroup()) {
    return { groupCode: availabilityCoupon.value }
  }

  if (availabilityCoupon.isPromotional() && promotionalCouponApplies) {
    return { coupon: availabilityCoupon.value }
  }

  return undefined
}
