import { FC, RefObject } from 'react'
import styles from './RoomTypeDropdown.module.scss'

import { Dropdown } from 'src/ui/components'
import { Text } from 'src/ui/components'
import {
  AvailabilityRoom,
  getBestPriceForRoom,
} from 'src/core/Availability/domain/Availability.model'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Price } from 'src/ui/components/molecules/Price'

interface Props {
  show: boolean
  triggerRef?: RefObject<HTMLButtonElement>
  onClickOutside: () => void
  onSelect: (room: AvailabilityRoom) => void
  onSelectAll: () => void
  rooms: AvailabilityRoom[]
  selectedRoomType: AvailabilityRoom | undefined
}

export const RoomTypeDropdown: FC<Props> = ({
  show,
  triggerRef,
  onClickOutside,
  onSelect,
  onSelectAll,
  rooms,
  selectedRoomType,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Dropdown
      show={show}
      onClickOutside={onClickOutside}
      triggerRef={triggerRef}
      className={styles.dropdown}
    >
      <ul className={styles.container}>
        <li>
          <button className={styles.button} onClick={onSelectAll}>
            {isUndefined(selectedRoomType) && (
              <Icon
                size="s"
                color="fill-neutral-04"
                icon={CheckIcon}
                className={styles.checkIcon}
                ariaLabel={trans('available-room_room-type-selected-option')}
              />
            )}
            <Text fontStyle={'m-500'}>
              {trans('available-room_room-type-filter_all', {
                totalRooms: rooms.length,
              })}
            </Text>
          </button>
        </li>
        {rooms.map(room => {
          const roomBestPrice = getBestPriceForRoom(room)
          return (
            <li key={room.id}>
              <button className={styles.button} onClick={() => onSelect(room)}>
                {selectedRoomType?.id === room.id && (
                  <Icon
                    size="s"
                    color="fill-neutral-04"
                    icon={CheckIcon}
                    className={styles.checkIcon}
                    ariaLabel={trans(
                      'available-room_room-type-selected-option',
                    )}
                  />
                )}
                <Text as="div" fontStyle={'m-500'} className={styles.roomName}>
                  {room.name}
                </Text>
                <Flex alignItems="center" gap="xxs">
                  <Text fontStyle="s-300">
                    {trans('available-room_room-type-filter_price-from')}
                  </Text>
                  <Price
                    fontStyle="s"
                    fontWeight="regular"
                    netPrice={roomBestPrice.netPrice}
                    grossPrice={roomBestPrice.grossPrice}
                    round="off"
                  />
                </Flex>
              </button>
            </li>
          )
        })}
      </ul>
    </Dropdown>
  )
}
