import { FC } from 'react'

import styles from './MealplansFilter.module.scss'
import {
  AvailabilityMealplan,
  getMealplanLowestNightlyPrice,
  getMealplanLowestPrice,
} from 'src/core/Availability/domain/Availability.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Filter } from './Filter'
import { classNames } from 'src/ui/utils/classnames'
import { MealplansHeader } from '../MealplansHeader'

export const MealplansFilter: FC<{
  mealplans: AvailabilityMealplan[]
  selectedMealplan: AvailabilityMealplan
  onMealplanSelected: (mealplan: AvailabilityMealplan) => void
  className: string
}> = ({ mealplans, onMealplanSelected, selectedMealplan, className }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <section className={classNames(styles.container, className)}>
      <MealplansHeader
        mealplans={mealplans}
        title={trans('available-rooms_mealplans-filter_title')}
      />
      <div className={styles.filters}>
        {mealplans.map(mealplan => {
          const lowestPrice = getMealplanLowestPrice(mealplan)
          const lowestAverageNightlyPrice =
            getMealplanLowestNightlyPrice(mealplan)

          return (
            <Filter
              key={mealplan.id}
              mealplanId={mealplan.id}
              price={lowestPrice}
              nightlyPrice={lowestAverageNightlyPrice}
              text={mealplan.name}
              isActive={selectedMealplan.id === mealplan.id}
              onClick={() => onMealplanSelected(mealplan)}
            />
          )
        })}
      </div>
    </section>
  )
}
