import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { ViewingAccelerator } from 'src/core/Reservation/domain/Reservation.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export type Variant = 'none' | 'lastRoom' | 'fewRooms' | 'viewingNow'

interface AcceleratorHook {
  variant: Variant
  quantity?: number
}

export const useAccelerators = (room: AvailabilityRoom): AcceleratorHook => {
  const { queryUtils } = useApplicationRouter()
  const hotelId = queryUtils.getRawParam('hotel')
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')
  const inventory = room.inventory

  const { data: viewingAccelerators } = useQueryService<ViewingAccelerator[]>(
    'get-viewing-now-accelerators',
    isDefined(hotelId) && inventory > 3 ? [hotelId] : null,
    () =>
      container.resolve('getViewingNowAccelerators')(
        hotelId!,
        checkIn!,
        checkOut!,
      ),
  )

  if (inventory === 1) {
    return {
      variant: 'lastRoom',
      quantity: inventory,
    }
  }

  if (inventory > 1 && inventory <= 3) {
    return {
      variant: 'fewRooms',
      quantity: inventory,
    }
  }

  if (inventory > 3 && isDefined(viewingAccelerators)) {
    const roomViewingAccelerator = viewingAccelerators.find(
      accelerator => accelerator.roomTypeId === room.id,
    )

    if (isDefined(roomViewingAccelerator) && roomViewingAccelerator.count > 0) {
      return {
        variant: 'viewingNow',
        quantity: roomViewingAccelerator.count,
      }
    }
  }

  return {
    variant: 'none',
    quantity: inventory,
  }
}
