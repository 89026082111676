import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './TaxInformation.module.scss'
import { Text } from 'src/ui/components'
import { FC } from 'react'
import { useMarket } from 'src/ui/contexts/MarketContext'

export const TaxInformation: FC = () => {
  const { trans } = useTrans(['new-reservation'])
  const { marketPrice } = useMarket()

  return (
    <div className={styles.wrapper}>
      <Text fontStyle="xs-300" color="mid">
        {trans(
          marketPrice?.netPrices
            ? 'available-rooms_net-taxes'
            : 'available-rooms_gross-taxes',
        )}
      </Text>
    </div>
  )
}
