import { Button, Modal, Text } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import styles from './RateSelectorModal.module.scss'

interface Props {
  couponId: string
  onSecondaryButtonSelected: () => void
  onPrimaryButtonSelected: () => void
  couponAppliesOnlyInMemberRate?: boolean
  couponAppliesOnlyInStandardRate?: boolean
  roomNames?: string[]
  isMultiroom?: boolean
  onClose?: () => void
}
export const RateSelectorModal = createModal(
  ({
    couponId,
    onSecondaryButtonSelected,
    onPrimaryButtonSelected,
    couponAppliesOnlyInMemberRate,
    couponAppliesOnlyInStandardRate,
    roomNames,
    isMultiroom = false,
    onClose,
  }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    const getTitle = () => {
      if (isMultiroom) {
        return trans('available-rooms_rate-selector_multiroom_title', {
          couponId,
        })
      }

      if (couponAppliesOnlyInMemberRate) {
        return trans('available-rooms_rate-selector_title', { couponId })
      }

      if (couponAppliesOnlyInStandardRate) {
        return trans('available-rooms_rate-selector_standard_title', {
          couponId,
        })
      }

      return trans('available-rooms_rate-selector_both_title', { couponId })
    }

    const getSubtitle = () => {
      if (isMultiroom) {
        return trans('available-rooms_rate-selector_multiroom_subtitle')
      }

      if (couponAppliesOnlyInMemberRate) {
        return trans('available-rooms_rate-selector_subtitle')
      }

      if (couponAppliesOnlyInStandardRate) {
        return trans('available-rooms_rate-selector_standard_subtitle')
      }

      return trans('available-rooms_rate-selector_both_subtitle')
    }

    const getSecondaryButtonLabel = () => {
      if (isMultiroom) {
        return trans('available-rooms_continue_multiroom_secondaryButton')
      }

      if (couponAppliesOnlyInStandardRate) {
        return trans('available-rooms_continue_standard_secondaryButton')
      }

      return trans('available-rooms_continue_secondaryButton')
    }

    const getPrimaryButtonLabel = () => {
      if (isMultiroom) {
        return trans('available-rooms_continue_multiroom_primaryButton')
      }

      if (couponAppliesOnlyInMemberRate) {
        return trans('available-rooms_continue_member_primaryButton')
      }

      if (couponAppliesOnlyInStandardRate) {
        return trans('available-rooms_continue_standard_primaryButton')
      }

      return trans('available-rooms_continue_both_primaryButton')
    }

    const getRoomNames = () => {
      if (isUndefined(roomNames) || roomNames.length === 0) {
        return null
      }

      return (
        <div className={styles.roomNames}>
          {roomNames.map((roomName, i) => (
            <Text fontStyle="m-700" key={`${roomName}_${i}`}>
              {roomName}
            </Text>
          ))}
        </div>
      )
    }

    return (
      <Modal
        type="passive"
        size="m"
        onClose={onClose}
        ariaLabel={trans('available-rooms_rate-selector_aria-label')}
        data-quantum-modal="RateSelectorModal"
      >
        <div className={styles.container}>
          <Text fontStyle="l-700" className="mb-m" as="p">
            {getTitle()}
          </Text>
          {getRoomNames()}
          <Text fontStyle="m-300" as="p">
            {getSubtitle()}
          </Text>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            type="submit"
            size={{
              mobile: 'fullWidth',
              laptop: 'fullWidth',
            }}
            variant="secondary"
            onClick={onSecondaryButtonSelected}
          >
            {getSecondaryButtonLabel()}
          </Button>
          <Button
            type="submit"
            size={{
              mobile: 'fullWidth',
              laptop: 'fullWidth',
            }}
            onClick={onPrimaryButtonSelected}
          >
            {getPrimaryButtonLabel()}
          </Button>
        </div>
      </Modal>
    )
  },
)
