import styles from './Info.module.scss'

import { FC } from 'react'

import { Skeleton } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'

export const InfoSkeleton: FC = () => {
  return (
    <section className={styles.container}>
      <Media mobile tablet>
        <div className={styles.carouselContainer}>
          <Skeleton width="100%" height={224} className={styles.infoSkeleton} />
        </div>
        <div className={styles.detailsContainer}>
          <Skeleton height={32} />
          <Skeleton height={72} />
          <Skeleton width={150} height={24} />
        </div>
      </Media>
      <Media laptop desktop>
        <div className={styles.carouselContainer}>
          <Skeleton width="100%" height={270} className={styles.infoSkeleton} />
        </div>
        <div className={styles.detailsContainer}>
          <Skeleton height={32} />
          <Skeleton height={72} />
          <Skeleton width={150} height={24} />
        </div>
      </Media>
    </section>
  )
}
