import { CSSProperties, FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Text, TextColor } from 'src/ui/components'
import { classNames } from 'src/ui/utils/classnames'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import styles from './StayButton.module.scss'
import { Media } from 'src/ui/styles/objects/Media'
import { StaySummary } from 'src/ui/views/AvailableRooms/useStaysSummary'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'

export type RoomButtonState = 'focused' | 'unfocused' | 'selected'

interface Props {
  roomNumber: number
  staySummary: StaySummary
  onClick: () => void
  state: RoomButtonState
  scrollable?: boolean
  className?: string
}

export const StayButton: FC<Props> = ({
  roomNumber,
  staySummary,
  state,
  onClick,
  className,
  scrollable = false,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const { room, occupancy } = staySummary
  const mealplan = room?.mealplan.name

  const textColor: { [key in RoomButtonState]: TextColor } = {
    focused: 'light',
    unfocused: 'mid',
    selected: 'primary',
  }

  const roomStyle: { [key in RoomButtonState]: CSSProperties } = {
    unfocused: {},
    focused: {
      borderColor: 'var(--b-color-fill-ocean-100)',
      backgroundColor: 'var(--b-color-fill-ocean-100)',
    },
    selected: {
      borderColor: 'var(--b-color-fill-ocean-20)',
      backgroundColor: 'var(--b-color-fill-ocean-20)',
    },
  }

  const getOccupancyText = (fromLaptop = false, mealplan?: string) => {
    const { adults, children } = occupancy

    const totalGuests = isDefined(children) ? adults + children : adults

    if (!fromLaptop || isUndefined(mealplan)) {
      return trans('stays-summary_room-button_total-occupancy', { totalGuests })
    }

    return trans('stays-summary_room-button_total-occupancy-with-mealplan', {
      totalGuests,
      mealplan,
    })
  }

  const isRoomFocused =
    state === 'selected' || (state === 'focused' && isDefined(room))

  return (
    <button
      className={classNames(
        styles.container,
        className,
        scrollable && styles.withScroll,
      )}
      style={roomStyle[state]}
      onClick={onClick}
      data-testid={`stay-button-${roomNumber}`}
      data-target-focused={state === 'focused'}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.name}>
          <Text
            fontStyle={state === 'unfocused' ? 'm-300' : 'm-700'}
            color={textColor[state]}
            className={styles.ellipsisText}
          >
            {isDefined(room)
              ? room.name
              : `${trans('stays-summary_room-button_title')} ${roomNumber}`}
          </Text>
          {isRoomFocused && (
            <Icon
              size="l"
              color={
                state === 'selected'
                  ? 'interactive-primary-active'
                  : 'icon-light'
              }
              icon={CheckIcon}
            />
          )}
        </div>

        <Media mobile tablet>
          <Text
            fontStyle="s-300"
            color={textColor[state]}
            className={styles.ellipsisText}
            as="p"
          >
            {getOccupancyText()}
          </Text>
        </Media>

        <Media laptop desktop>
          <Text
            fontStyle="s-300"
            color={textColor[state]}
            className={styles.ellipsisText}
            as="p"
          >
            {getOccupancyText(true, mealplan)}
          </Text>
        </Media>
      </div>
    </button>
  )
}
