import { useEffect, useState } from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { AvailabilityRoomTypeFilter } from 'src/core/Availability/domain/Availability.model'
import { container } from 'src/core/Shared/_di'

export function useRoomTypeFiltersInUrl() {
  const [isFiltering, setIsFiltering] = useState(false)
  const { queryUtils, navigate } = useApplicationRouter()
  const [selectedFilter, setSelectedFilter] = useState<
    AvailabilityRoomTypeFilter | undefined
  >(undefined)

  useEffect(() => {
    try {
      const roomTypeFilterParam = queryUtils.getRoomTypeFilterParam()

      setSelectedFilter(roomTypeFilterParam)
    } catch (error) {
      container.resolve('logger').error(error)
    }
  }, [queryUtils])

  const setFilter = async (selectedFilter: AvailabilityRoomTypeFilter) => {
    setIsFiltering(true)

    // TODO ¿Cómo sequiere enviar la analítica?
    // container
    //   .resolve('analytics')
    //   .actions.availableRooms.changeBoardBasis(selectedFilter)

    const query = queryUtils.buildRoomTypeFiltersQuery(selectedFilter)
    await navigate.toSameWithoutReload(query)
    setIsFiltering(false)
  }

  const removeAllRoomTypeFilters = async () => {
    const query = queryUtils.buildQueryWithoutRoomTypeFilter()
    await navigate.toSameWithoutReload(query)
    setIsFiltering(false)
  }

  const startFilterAll = () => {
    setIsFiltering(true)

    // TODO ¿Cómo sequiere enviar la analítica?
    // container.resolve('analytics').actions.availableRooms.changeBoardBasis()

    removeAllRoomTypeFilters()
  }

  return {
    selectedFilter,
    isFiltering,
    setFilter,
    startFilterAll,
    removeAllRoomTypeFilters,
  }
}
