import { FC } from 'react'
import { Notification } from 'src/ui/components'
import { Translator, useTrans } from 'src/ui/hooks/useTrans'
import { NotificationTypes } from 'src/ui/components/molecules/Notification/NotificationTypes.model'
import { HowCouponAppliesInAvailability } from 'src/ui/contexts/CouponsContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'
import {
  CouponValidationResult,
  CouponValidationType,
} from 'src/core/Hotel/domain/CouponValidation.model'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'

interface Content {
  validTitle: string
  validDescription: string
  invalidTitle: string
  invalidDescription?: string
}

interface Props {
  onClose: () => void
  coupon: AvailabilityCoupon
  closeAutomaticallyAfter?: number
  howCouponAppliesInAvailability: HowCouponAppliesInAvailability
  couponValidationResult: CouponValidationResult
}

export const CouponsNotification: FC<Props> = ({
  onClose,
  coupon,
  closeAutomaticallyAfter,
  howCouponAppliesInAvailability,
  couponValidationResult,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const calculateContent = (): Content => {
    const notificationType = `-${howCouponAppliesInAvailability}`

    const { invalidTitle, invalidDescription } =
      calculateCouponInvalidMessages()

    // DEPRECAR validTitle y validDescription para all-member-mixed
    return {
      validTitle: `coupon-notification${notificationType}_valid_title`,
      validDescription: `coupon-notification${notificationType}_valid_description`,
      invalidTitle,
      invalidDescription,
    }
  }

  const calculateCouponInvalidMessages = () => {
    if (couponValidationResult.type === CouponValidationType.INVALID) {
      return {
        invalidTitle: trans('coupon-notification_invalid_title', {
          couponId: coupon.value,
        }),
        invalidDescription: trans('coupon-notification_invalid_description'),
      }
    }

    if (couponValidationResult.type === CouponValidationType.NOT_VALID_YET) {
      return {
        invalidTitle: trans('coupon-notification_invalid_not_valid_yet_title', {
          couponId: coupon.value,
        }),
        invalidDescription: trans(
          'coupon-notification_invalid_not_valid_yet_description',
          {
            date: datesManager.formatDateToLocale(
              couponValidationResult.validity!.bookingStartDate,
              'short',
            ),
          },
        ),
      }
    }

    if (couponValidationResult.type === CouponValidationType.TIMED_OUT) {
      return {
        invalidTitle: trans('coupon-notification_invalid_timed_out_title', {
          couponId: coupon.value,
        }),
        invalidDescription: trans(
          'coupon-notification_invalid_timed_out_description',
          {
            date: datesManager.formatDateToLocale(
              couponValidationResult.validity!.bookingEndDate,
              'short',
            ),
          },
        ),
      }
    }

    if (
      couponValidationResult.type === CouponValidationType.NOT_IN_DATE_RANGE
    ) {
      return {
        invalidTitle: trans(
          'coupon-notification_invalid_not_in_date_range_title',
          {
            couponId: coupon.value,
          },
        ),
        invalidDescription: trans(
          'coupon-notification_invalid_not_in_date_range_description',
          {
            startDate: datesManager.formatDateToLocale(
              couponValidationResult.validity!.arrivalStartDate,
              'short',
            ),
            endDate: datesManager.formatDateToLocale(
              couponValidationResult.validity!.arrivalEndDate,
              'short',
            ),
          },
        ),
      }
    }

    if (
      couponValidationResult.type === CouponValidationType.VALID &&
      !coupon.applies
    ) {
      return {
        invalidTitle: trans('coupon-notification_valid_not-applied_title', {
          couponId: coupon.value,
        }),
        invalidDescription: trans(
          'coupon-notification_valid_not-applied_description',
        ),
      }
    }

    return {
      invalidTitle: '',
      invalidDescription: '',
    }
  }

  const content = calculateContent()
  const title = getTitle(coupon, content, trans)
  const description = getDescription(coupon, content, trans)
  const type = getType(coupon, howCouponAppliesInAvailability!)

  return (
    <Notification
      ariaLabel={`notification-${type}`}
      title={title}
      description={description}
      type={type}
      data-testid={`notification-icon-${type}`}
      data-quantum-warning={`invalid coupon: ${couponValidationResult.invalidReason ?? couponValidationResult.type}`}
      isOpen={true}
      closeAutomaticallyAfter={closeAutomaticallyAfter}
      onClose={onClose}
    />
  )
}

const getTitle = (
  coupon: AvailabilityCoupon,
  content: Content,
  trans: Translator,
) => {
  if (coupon.applies) {
    return trans(content.validTitle, {
      couponId: coupon.value.trim(),
    })
  }

  return content.invalidTitle
}

const getDescription = (
  coupon: AvailabilityCoupon,
  content: Content,
  trans: Translator,
) => {
  if (coupon.applies) {
    return trans(content.validDescription)
  } else if (isDefined(content.invalidDescription)) {
    return content.invalidDescription
  }

  return ''
}

const getType = (
  coupon: AvailabilityCoupon,
  howCouponAppliesInAvailability: HowCouponAppliesInAvailability,
): NotificationTypes => {
  if (coupon.applies) {
    if (howCouponAppliesInAvailability === 'every') {
      return 'success'
    }

    return 'info'
  }

  return 'error'
}
