import { Divider, Modal } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './HotelPromotionModal.module.scss'
import { Promotion } from 'src/core/Hotel/domain/Promotion.model'
import { HotelPromotionsHeader } from 'src/ui/views/AvailableRooms/HotelPromotionsHeader'
import { HotelPromotions } from 'src/ui/views/AvailableRooms/HotelPromotions'

interface Props {
  hotelPromotions: Promotion[]
  onCloseModal: () => void
}

export const HotelPromotionModal = createModal(({ hotelPromotions }: Props) => {
  const { trans } = useTrans(['common', 'new-reservation'])

  return (
    <Modal
      type="transactional"
      size="l"
      ariaLabel={trans('hotel_alert-barcelo_exclusive')}
      mobileStyle="bottom"
      data-quantum-modal="HotelPromotionModal"
    >
      <div className={styles.container}>
        <HotelPromotionsHeader />
        <Divider dir="horizontal" />
        <HotelPromotions hotelPromotions={hotelPromotions} showAll />
      </div>
    </Modal>
  )
})
