import { FC } from 'react'
import styles from './MyBarceloBanner.module.scss'
import { Text } from 'src/ui/components'
import { Card } from 'src/ui/views/AvailableRooms/MyBarceloBanner/Card/Card'
import { classNames } from 'src/ui/utils/classnames'
import { Translate } from 'src/ui/i18n/Translate'

interface Props {
  className?: string
}

export const MyBarceloBanner: FC<Props> = ({ className }) => {
  return (
    <section className={classNames(styles.container, className)}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <Text
            as="h3"
            fontStyle={{
              mobile: 'l-700',
              laptop: '2xl-700',
              desktop: 'l-700',
            }}
            color="light"
            className={styles.title}
          >
            <Translate
              i18nKey="benefits_no-level-user_title"
              components={{
                br: <br />,
              }}
            />
          </Text>
        </div>
        <Card />
      </div>
    </section>
  )
}
