import {
  Availability,
  AvailabilityCriteria,
} from 'src/core/Availability/domain/Availability.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityRepository } from 'src/core/Availability/domain/Availability.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'

export const getAllAvailability =
  ({ availabilityRepository, analytics }: Dependencies) =>
  async (
    request: AvailabilityCriteria | undefined,
  ): Promise<Availability | undefined> => {
    if (isUndefined(request)) {
      return undefined
    }

    const availability = await availabilityRepository.all(request)
    analytics.requests.availability(availability)

    return availability
  }

interface Dependencies {
  availabilityRepository: AvailabilityRepository
  analytics: Analytics
}
