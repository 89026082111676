import { createModal } from 'src/ui/hooks/useModal'
import { AsideModal } from 'src/ui/components'
import { Tabs } from 'src/ui/components'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'

interface Props {
  hotel: Hotel
}

export const HotelDetailModal = createModal(({ hotel }: Props) => {
  // llamada a la api del detalle del hotel
  const tabs = [{ title: 'Información', container: <></> }]

  return (
    <AsideModal title={hotel.name}>
      <Tabs tabs={tabs} />
    </AsideModal>
  )
})
