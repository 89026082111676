import { createModal } from 'src/ui/hooks/useModal'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { Button, Modal } from 'src/ui/components'
import { useDatesRangeForm } from 'src/ui/hooks/useDatesRangeForm'
import { GuestsFormValues, useGuestsForm } from 'src/ui/hooks/useGuestsForm'
import { useTrans } from 'src/ui/hooks/useTrans'
import { getGuestsResumeTitle } from 'src/ui/i18n/getGuestsResumeTitle'
import styles from './EditAvailabilityCriteriaMobile.module.scss'
import { CouponsFormValues, useCouponsForm } from 'src/ui/hooks/useCouponsForm'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { FocusOn } from '../AvailableRoomsLayout.model'
import { DatesPill } from './DatesPill'
import { OccupancyPill } from './GuestsPill'
import { CouponsPill } from './CouponsPill'
import { Media } from 'src/ui/styles/objects/Media'
import { User } from 'src/core/User/domain/User'
import { useEffect } from 'react'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'

interface Props {
  adultsList: number[]
  childrenList: number[]
  childrenAges?: Array<Array<number>>
  maxGuests: number
  maxChildren: number
  maxAdults: number
  maxChildAge: number
  showChildren: boolean
  focus: FocusOn
  checkIn: Date
  checkOut: Date
  onClose: () => void
  setFocus: (focus: FocusOn) => void
  coupon: AvailabilityCoupon | undefined
  className?: string
  onSubmit: (
    dates: CheckInCheckOut,
    guests: GuestsFormValues,
    coupon: CouponsFormValues | undefined,
  ) => void
  user: User
  initialDatesError: boolean
  onValidateCoupon: (couponId: string) => Promise<CouponValidationResult>
}

export const EditAvailabilityCriteriaMobile = createModal(
  ({
    adultsList,
    childrenList,
    focus,
    checkIn,
    checkOut,
    childrenAges,
    maxGuests,
    maxChildren,
    maxAdults,
    maxChildAge,
    showChildren,
    onClose,
    setFocus,
    coupon,
    onSubmit,
    className,
    user,
    initialDatesError,
    onValidateCoupon,
  }: Props) => {
    const {
      methods: couponsMethods,
      getCoupon,
      areValuesSameAsOld: areCouponValuesSameAsOld,
    } = useCouponsForm(coupon)

    const {
      methods: datesMethods,
      areValuesSameAsOld: areDateValuesSameAsOld,
      getValues: getDates,
      resetForm: resetDatesForm,
      hasErrors: hasDatesErrors,
      setManualErrorMessage,
    } = useDatesRangeForm({
      checkIn,
      checkOut,
    })

    const { trans } = useTrans(['new-reservation', 'common'])

    const startDate = datesMethods.watch('dates.checkIn') ?? checkIn
    const endDate = datesMethods.watch('dates.checkOut') ?? checkOut

    const {
      methods: guestsMethods,
      areValuesSameAsOld: areGuestValuesSameAsOld,
      resetForm: resetGuestsForm,
      expandedRoomId,
      uniqueIds,
      canAddMoreRooms,
      roomsNumber,
      hasErrors: hasGuestsErrors,
      getAllValues: getGuests,
      setExpandedRoomId,
      handleAddRoom,
      handleDeleteRoom,
      handleOpenRoom,
    } = useGuestsForm({
      adultsList,
      childrenList,
      childrenAges,
    })

    useEffect(() => {
      if (!initialDatesError) {
        return
      }

      setManualErrorMessage(trans('new-reservation:dates-modal_initial-error'))
    }, [initialDatesError])

    const adultKeys = Object.keys(guestsMethods.getValues()).filter(
      key => key.includes('adults#') && uniqueIds.includes(key.split('#')[1]),
    )

    const adults = guestsMethods.watch(adultKeys)
    const childrenKeys = Object.keys(guestsMethods.getValues()).filter(
      key => key.includes('children#') && uniqueIds.includes(key.split('#')[1]),
    )
    const children = guestsMethods.watch(childrenKeys)

    const guestsDraftTitle = getGuestsResumeTitle({
      adultsList: adults,
      childrenList: children,
      trans,
      showRooms: false,
    })

    const validateFields = async () => {
      const isGuestsValid = await guestsMethods.trigger()
      const isDatesValid = await datesMethods.trigger()

      return isGuestsValid && isDatesValid
    }

    const handleSubmit = async () => {
      const isFormValid = await validateFields()

      if (!isFormValid) {
        return
      }

      const areSameAsOldValues =
        areGuestValuesSameAsOld() &&
        areDateValuesSameAsOld() &&
        areCouponValuesSameAsOld()

      if (areSameAsOldValues) {
        onClose()
        return
      }

      const guests = getGuests()
      const dates = getDates()
      const coupon = getCoupon()

      onSubmit(dates, guests, coupon)
      onClose()
    }

    const handleOpenDates = async () => {
      const isValid = await guestsMethods.trigger()
      if (!isValid) {
        return
      }

      setFocus('dates')
    }

    const handleOpenGuests = async () => {
      const isValid = await datesMethods.trigger()
      const { checkIn, checkOut } = datesMethods.getValues('dates')
      if (!isValid || !checkIn || !checkOut) {
        return
      }

      if (focus === 'dates') {
        setExpandedRoomId(uniqueIds[0])
      }

      setFocus('occupancy')
    }

    const handleOpenCoupons = async () => {
      const formValidateValues = await Promise.all([
        guestsMethods.trigger(),
        datesMethods.trigger(),
      ])
      if (!formValidateValues.every(isValid => Boolean(isValid))) {
        return
      }

      setFocus('coupons')
    }

    const close = () => {
      resetGuestsForm()
      resetDatesForm()
      onClose()
    }

    const formattedDates = `${Time.fromDate(startDate).format(
      'D MMM',
    )} - ${Time.fromDate(endDate).format('D MMM')}`

    const couponType = couponsMethods.watch('type')

    return (
      <Media mobile tablet>
        <Modal
          size="m"
          mobileStyle="fullscreen"
          type="transactional"
          onClose={close}
          className={className}
          withButtonsFooter
          data-quantum-modal="EditAvailabilityCriteriaMobile"
        >
          <div className={styles.container}>
            <DatesPill
              datesMethods={datesMethods}
              focused={focus === 'dates'}
              hasError={hasDatesErrors}
              onOpen={handleOpenDates}
              datesText={formattedDates}
            />

            <OccupancyPill
              focused={focus === 'occupancy'}
              onOpen={handleOpenGuests}
              hasError={hasGuestsErrors}
              guestsMethods={guestsMethods}
              uniqueIds={uniqueIds}
              maxGuests={maxGuests}
              maxChildren={maxChildren}
              maxAdults={maxAdults}
              maxChildAge={maxChildAge}
              showChildren={showChildren}
              expandedRoomId={expandedRoomId}
              onDeleteRoom={handleDeleteRoom}
              onOpenRoom={handleOpenRoom}
              canAddMoreRooms={canAddMoreRooms}
              onAddRoom={handleAddRoom}
              guestsDraftTitle={guestsDraftTitle}
              roomsNumber={roomsNumber}
            />

            <CouponsPill
              methods={couponsMethods}
              focused={focus === 'coupons'}
              onOpen={handleOpenCoupons}
              user={user}
              onValidateCoupon={onValidateCoupon}
              couponTypeSelected={couponType}
              onSubmit={handleSubmit}
            />

            <div className={styles.footer}>
              <Button onClick={handleSubmit}>
                {trans('new-reservation:occupancy-form_accept')}
              </Button>
            </div>
          </div>
        </Modal>
      </Media>
    )
  },
)
