import {
  AvailabilityRate,
  AvailabilityFlatRate,
} from 'src/core/Availability/domain/Availability.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export interface SelectableRates {
  memberRate: AvailabilityRate | undefined
  standardRate: AvailabilityFlatRate | AvailabilityRate | undefined
}
export interface RatePolicies {
  id: string
  rate: AvailabilityRate
  name: string
  guarantee: string
  cancellation: string
  isCancellationHighlighted: boolean
}

export function getRatePoliciesWithRateId(
  ratePolicies: RatePolicies[],
  rateId?: string,
) {
  return ratePolicies.find(
    ratePolicy =>
      ratePolicy.rate.id === rateId ||
      ratePolicy.rate.relatedRate?.rate.id === rateId,
  )
}

export function getAvailableRatesFrom(
  ratePolicies: RatePolicies,
): SelectableRates {
  if (ratePolicies.rate.isMember) {
    return {
      memberRate: ratePolicies.rate,
      standardRate: ratePolicies.rate.relatedRate?.rate,
    }
  } else {
    return {
      memberRate: undefined,
      standardRate: ratePolicies.rate,
    }
  }
}

export function memberRateHasTag(memberRate: AvailabilityRate | undefined) {
  return isDefined(memberRate) && memberRate.isMember
}
