import styles from './Room.module.scss'
import mealplansFilterStyles from './RateSelector/MealplansFilter/MealplansFilter.module.scss'
import rateSelectorStyles from './RateSelector/RateSelector.module.scss'
import { FC } from 'react'

import { HeaderSkeleton } from './Header'
import { InfoSkeleton } from './Info'

import { useTrans } from 'src/ui/hooks/useTrans'
import Skeleton from 'react-loading-skeleton'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Media } from 'src/ui/styles/objects/Media'
import { Divider } from 'src/ui/components'

export const RoomSkeleton: FC = () => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <article
      aria-label={trans('available-rooms_room-details-skeleton_aria-label')}
      className={styles.container}
    >
      <HeaderSkeleton />

      <section className={styles.info}>
        <InfoSkeleton />
      </section>
      <section className={styles.rateSelectorContainer}>
        <div className={mealplansFilterStyles.container}>
          <div className={mealplansFilterStyles.title}>
            <Skeleton width={250} height={30} />
          </div>
          <Flex className={mealplansFilterStyles.filters}>
            <Skeleton height={54} width={160} />
            <Skeleton height={54} width={130} />
          </Flex>
        </div>
        <Media laptop desktop>
          <Divider dir="horizontal" />
        </Media>
        <section className={rateSelectorStyles.rateSelectorContainer}>
          <div>
            <Skeleton width={250} height={30} className="mb-s" />
            <Flex gap="s" direction="column" alignItems="stretch">
              <Skeleton width="100%" height={75} />
            </Flex>
          </div>
          <div>
            <Skeleton width={250} height={30} className="mb-s" />
            <Flex gap="s" direction="column" alignItems="stretch">
              <Skeleton width="100%" height={75} />
            </Flex>
          </div>
        </section>
      </section>
    </article>
  )
}
