import { FC } from 'react'
import styles from './Benefit.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Color } from 'src/ui/styles/settings'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'

export interface BenefitModel {
  title: string
  text: string
}

interface Props {
  benefit: BenefitModel
  userConfigByLevel: {
    color: string
    textColor: Color
  }
}

export const Benefit: FC<Props> = ({ benefit, userConfigByLevel }) => {
  const { color, textColor } = userConfigByLevel
  return (
    <li className={styles.item}>
      <div
        className={styles.circle}
        style={{
          backgroundColor: color,
        }}
      >
        <Icon size="s" color={textColor} icon={CheckIcon} />
      </div>

      <Flex direction="column">
        <Text fontStyle="m-700" color="dark">
          {benefit.title}
        </Text>
        <Text fontStyle="m-300" color="dark">
          {benefit.text}
        </Text>
      </Flex>
    </li>
  )
}
