import styles from './MealplansInfoButton.module.scss'
import { useModal } from 'src/ui/hooks/useModal'
import { MealplansModal } from './MealplansModal'
import InfoIcon from 'src/ui/components/atoms/Icon/_icons/interaction/InfoIcon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { FC } from 'react'
import { AvailabilityMealplan } from 'src/core/Availability/domain/Availability.model'

export const MealplansInfoButton: FC<{
  mealplans: AvailabilityMealplan[]
}> = ({ mealplans }) => {
  const { trans } = useTrans(['new-reservation'])
  const { showModal: showMealplansModal } = useModal(MealplansModal)
  const mealplanIds = mealplans?.map(mp => mp.id)

  return (
    <button
      onClick={() => showMealplansModal({ mealplanIds })}
      aria-label={trans('available-rooms_mealplans_info')}
    >
      <Icon
        icon={InfoIcon}
        size="l"
        color="none"
        className={styles.mealplansInfoButton}
      />
    </button>
  )
}
