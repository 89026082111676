import styles from './Filter.module.scss'
import { Text } from 'src/ui/components'
import { MouseEventHandler, useRef, FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import {
  AvailabilityMealplanId,
  MealplanFilterWithRelatedLowestPrice,
} from 'src/core/Availability/domain/Availability.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { Price as PriceModel } from 'src/core/Shared/domain/Price.model'
import { Price } from 'src/ui/components/molecules/Price'

interface Props {
  mealplanId: AvailabilityMealplanId
  text: string
  isActive: boolean
  price: MealplanFilterWithRelatedLowestPrice['lowestPrice'] | undefined
  nightlyPrice:
    | {
        netPrice: PriceModel
        grossPrice: PriceModel
      }
    | undefined
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Filter: FC<Props> = ({
  mealplanId,
  text,
  isActive,
  onClick,
  price,
  nightlyPrice,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { formatPrice } = usePriceFormatter()
  const ref = useRef<HTMLButtonElement>(null)

  const textColor = isActive ? 'light' : 'dark'

  return (
    <button
      ref={ref}
      className={classNames(styles.filter, isActive && styles.filterActive)}
      onClick={onClick}
      data-testid={text}
      aria-pressed={isActive}
      data-target-mealplan-stay-price={
        isDefined(price) &&
        formatPrice(price.grossPrice, { roundOff: true }, price.netPrice)
      }
      data-target-mealplan-average-nightly-price={
        isDefined(nightlyPrice) &&
        formatPrice(
          nightlyPrice.grossPrice,
          { roundOff: true },
          nightlyPrice.netPrice,
        )
      }
      data-cxo-mealplan-id={mealplanId}
    >
      <Text fontStyle={{ mobile: 's-500', laptop: 'm-500' }} color={textColor}>
        {text}
      </Text>
      {isDefined(nightlyPrice) && (
        <Flex alignItems="center" gap="xxs">
          <Text fontStyle="s-300" color={textColor}>
            {trans('mealplan-filters_price-from')}
          </Text>
          <Price
            fontStyle="s"
            color={textColor}
            fontWeight="regular"
            grossPrice={nightlyPrice.grossPrice}
            netPrice={nightlyPrice.netPrice}
            round="off"
          />
        </Flex>
      )}
    </button>
  )
}
