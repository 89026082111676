import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './DotRatings.module.scss'
import { getReviewsAverageDotsType } from 'src/core/Hotel/domain/getReviewsAverageDotsType'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  ratings?: string
}

export const DotRatings: FC<Props> = ({ ratings }) => {
  const averageDotsType = getReviewsAverageDotsType(ratings)

  const { trans } = useTrans(['new-reservation'])

  return (
    <div
      className={styles.dotWrapper}
      aria-label={trans('tripadvisor_average-aria-label', {
        rating: ratings,
      })}
    >
      {averageDotsType.map((dotType, i) => (
        <span
          key={i}
          className={classNames(styles.dot, styles[dotType])}
        ></span>
      ))}
    </div>
  )
}
