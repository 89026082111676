import { useEffect } from 'react'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import { Cart } from 'src/core/Cart/domain/Cart.model'
import { AvailabilityCriteria } from 'src/core/Availability/domain/Availability.model'

export const useDeleteObsoleteCart = (
  cart: Cart | undefined,
  availabilityCriteria: AvailabilityCriteria | undefined,
) => {
  useEffect(() => {
    if (isUndefined(cart) || isUndefined(availabilityCriteria)) {
      return
    }

    container.resolve('deleteObsoleteCart')(cart, availabilityCriteria)
  }, [availabilityCriteria, cart])
}
