import styles from './AvailableRooms.module.scss'
import { FC, Fragment, useEffect, useRef } from 'react'
import { Media } from 'src/ui/styles/objects/Media'
import { Text } from 'src/ui/components'
import {
  AvailabilityRoom,
  AvailabilityRoomTypeFilter,
  AvailabilityStay,
  getTotalNightsFromAvailability,
} from 'src/core/Availability/domain/Availability.model'
import { OnRateSelected } from './AvailableRooms.model'
import {
  isDefined,
  isNull,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { AvailabilityError } from 'src/ui/views/AvailableRooms/AvailabilityError'
import { AvailabilityError as AvailabilityErrorModel } from 'src/core/Availability/domain/AvailabilityError'
import { useCart } from 'src/ui/contexts/CartContext'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { MyBarceloBanner } from './MyBarceloBanner'
import { useModal } from 'src/ui/hooks/useModal'
import { ExpiredCartModal } from 'src/ui/views/_components/ExpiredCartModal'
import {
  ReservedRate,
  SelectedRate,
} from 'src/core/Reservation/domain/Reservation.model'
import { useHotel } from 'src/ui/hooks/queries/useHotel'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { CouponsNotification } from 'src/ui/views/AvailableRooms/CouponsNotification'
import { Room, RoomSkeleton } from './Room'
import { classNames } from 'src/ui/utils/classnames'
import { RoomTypeFilter } from './RoomTypeFilter'
import { HotelHeroMobile } from './HotelHeroMobile'
import { HotelHeroDesktop } from './HotelHeroDesktop'
import { useDatesModal } from './AvailabilityError/useDatesModal'
import { BannerBCom } from 'src/ui/views/AvailableRooms/BannerBCom'
import { useHotelPromotions } from 'src/ui/hooks/queries/useHotelPromotions'
import { Promotion } from 'src/core/Hotel/domain/Promotion.model'
import { HotelPromotionModal } from 'src/ui/views/_components/HotelPromotionModal'
import { BookingStepperMobile } from 'src/ui/views/_components/BookingStepperMobile'
import { GroupCodeNotification } from 'src/ui/views/AvailableRooms/GroupCodeNotification'

interface Props {
  filteredAvailability: AvailabilityStay | undefined
  allRooms: AvailabilityRoom[] | undefined
  isAvailabilityLoading: boolean
  reservedRate?: ReservedRate
  onRateSelected: OnRateSelected
  onFilterAllRoomType: () => void
  onFilterRoomType: (filter: AvailabilityRoomTypeFilter) => void
  selectedRoomTypeFilter: AvailabilityRoomTypeFilter | undefined
  error?: AvailabilityErrorModel
  selectedRate: SelectedRate | undefined
  isMultiroom: boolean
  reservationInProgress: boolean
}

export const AvailableRooms: FC<Props> = ({
  filteredAvailability,
  allRooms,
  error,
  reservedRate,
  onRateSelected,
  isAvailabilityLoading,
  selectedRate,
  isMultiroom,
  onFilterAllRoomType,
  onFilterRoomType,
  selectedRoomTypeFilter,
  reservationInProgress,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const hotel = useHotel()
  const {
    hasToShowCouponNotification,
    howCouponAppliesInAvailability,
    availabilityCoupon,
    hideCouponNotification,
    couponValidationResult,
  } = useCoupons()

  const { hotelPromotions, isLoadingPromotions } = useHotelPromotions()
  const hasHotelPromotions =
    isDefined(hotelPromotions) && hotelPromotions.length > 0

  const isLoading = isUndefined(filteredAvailability) || isAvailabilityLoading

  const totalNightsFromAvailability =
    getTotalNightsFromAvailability(filteredAvailability)
  const isOneNight =
    isDefined(totalNightsFromAvailability) && totalNightsFromAvailability === 1

  const heightHotelSection = useRef<HTMLDivElement>(null)

  const { cart } = useCart()

  const { showModal: showExpiredCartModal } = useModal(ExpiredCartModal)
  const {
    showModal: showHotelPromotionModal,
    hideModal: hideHotelPromotionModal,
  } = useModal(HotelPromotionModal)
  const { showDatesModal } = useDatesModal()

  useEffect(() => {
    const isCartExpired = isDefined(cart) && cart.isExpired
    if (isCartExpired) {
      showExpiredCartModal()
    }
  }, [showExpiredCartModal, cart])

  // Función pública temporal sólo para la personalización de cupón inválido en paso 1 de CXO
  useEffect(() => {
    //@ts-ignore
    window.cxoOpenDatesModal = () => {
      showDatesModal()
    }

    return () => {
      //@ts-ignore
      delete window.cxoOpenDatesModal
    }
  }, [showDatesModal])

  const getHotelSectionHeight = () => {
    if (isNull(heightHotelSection.current)) {
      return {}
    }

    return {
      style: {
        minHeight: `calc(
          100vh - var(--header-height) - var(--footer-height) - ${heightHotelSection.current.clientHeight}px)`,
      },
    }
  }

  const handleShowHotelPromotionModal = async (
    hotelPromotions: Promotion[],
  ) => {
    await showHotelPromotionModal({
      hotelPromotions,
      onCloseModal: hideHotelPromotionModal,
    })
  }

  const renderNotification = () => {
    if (hasToShowCouponNotification && availabilityCoupon) {
      if (availabilityCoupon.isGroup()) {
        return (
          <GroupCodeNotification
            onClose={hideCouponNotification}
            coupon={availabilityCoupon}
            closeAutomaticallyAfter={Time.seconds(10)}
          />
        )
      }

      if (isDefined(couponValidationResult)) {
        return (
          <CouponsNotification
            onClose={hideCouponNotification}
            coupon={availabilityCoupon}
            closeAutomaticallyAfter={Time.seconds(10)}
            howCouponAppliesInAvailability={howCouponAppliesInAvailability}
            couponValidationResult={couponValidationResult}
          />
        )
      }
    }
  }

  return (
    <>
      <Media laptop tablet mobile>
        <div>
          <HotelHeroMobile hotel={hotel} />
        </div>
      </Media>
      <div
        className={classNames(
          styles.container,
          isDefined(error) && styles.hasError,
        )}
      >
        <div
          className={classNames(styles.bodyContent)}
          data-target-content="available-rooms-body"
        >
          {isDefined(error) ? (
            <div className={styles.errorSection} {...getHotelSectionHeight()}>
              <AvailabilityError error={error} />
            </div>
          ) : (
            <>
              <div className={styles.filterSectionWrapper}>
                <div className={styles.filterWrapper}>
                  <Media mobile>
                    <div className={styles.bookingStepperMobileWrapper}>
                      <BookingStepperMobile currentStep={1} />
                    </div>
                  </Media>
                  <div className={styles.title}>
                    <Text
                      as="h1"
                      fontStyle={{ mobile: 'l-700', laptop: '3xl-700' }}
                      color="dark"
                    >
                      {trans('available-rooms_title')}
                    </Text>
                  </div>
                  <div
                    className={classNames(
                      !hasHotelPromotions && styles.roomTypeFilterContainer,
                    )}
                  >
                    <RoomTypeFilter
                      isLoading={isLoading}
                      rooms={allRooms}
                      onRoomTypeSelected={onFilterRoomType}
                      onAllSelected={onFilterAllRoomType}
                      selectedRoomType={allRooms?.find(
                        room => room.id === selectedRoomTypeFilter,
                      )}
                    />
                  </div>
                </div>
                {hasHotelPromotions && (
                  <BannerBCom
                    hotelPromotions={hotelPromotions}
                    isLoadingPromotions={isLoadingPromotions}
                    onMoreInfoClick={handleShowHotelPromotionModal}
                  />
                )}
              </div>
              {!isLoading ? (
                <section
                  aria-label={trans('available-rooms_rooms-list_aria-label')}
                  className={styles.rooms}
                  data-target-availability-loading={isLoading}
                >
                  {filteredAvailability.rooms.map((room, index) => {
                    const selectedRateInRoom =
                      selectedRate?.roomId === room.id
                        ? selectedRate
                        : undefined

                    return (
                      <Fragment key={room.id}>
                        <Room
                          room={room}
                          hotel={hotel}
                          reservedRate={reservedRate}
                          onRateSelected={onRateSelected}
                          selectedRate={selectedRateInRoom}
                          isMultiroom={isMultiroom}
                          isOneNight={isOneNight}
                          totalNightsFromAvailability={
                            totalNightsFromAvailability ?? 0
                          }
                          reservationInProgress={reservationInProgress}
                        />
                      </Fragment>
                    )
                  })}
                </section>
              ) : (
                <section
                  aria-label={trans(
                    'available-rooms_rooms-list-skeleton_aria-label',
                  )}
                  className={styles.rooms}
                  data-target-availability-loading={isLoading}
                >
                  <RoomSkeleton />
                </section>
              )}
            </>
          )}
          {renderNotification()}
        </div>
        <aside
          className={classNames(styles.asideContainer)}
          data-target-content="available-rooms-aside"
        >
          <Media desktop>
            <div className={styles.asideContent}>
              <HotelHeroDesktop className="mb-m" hotel={hotel} />
              <MyBarceloBanner />
            </div>
          </Media>
        </aside>
      </div>
    </>
  )
}
