import { FC } from 'react'
import { Tag } from 'src/ui/components'
import styles from './CouponTag.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'

interface Props {
  promotionalCoupon: PromotionalValidCoupon
}

export const CouponTag: FC<Props> = ({ promotionalCoupon }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Tag className={styles.couponTag}>
      {promotionalCoupon.discount.type === 'PERCENTAGE'
        ? trans('available-rooms_type-rate_coupon-info-with-percentage', {
            percentage: promotionalCoupon.discount.value,
            coupon: promotionalCoupon.id,
          })
        : trans('available-rooms_type-rate_coupon-info', {
            coupon: promotionalCoupon.id,
          })}
    </Tag>
  )
}
