import { Skeleton } from 'src/ui/components'
import { classNames } from 'src/ui/utils/classnames'
import styles from './NearbyHotelsSkeleton.module.scss'
import containerStyles from './NearbyHotels.module.scss'
import { NearbyHotelCardSkeleton } from './NearbyHotelCard/NearByHotelCardSkeleton'
import { Media } from 'src/ui/styles/objects/Media'

export const NearbyHotelsSkeleton = () => {
  return (
    <div className={styles.container} data-testid="nearby-hotels-skeleton">
      <Skeleton width={180} height={36} />
      <Media tablet mobile>
        <Skeleton width={300} height={24} className="mt-m" />
      </Media>
      <Media laptop desktop>
        <Skeleton width={380} height={24} className="mt-m" />
      </Media>
      <div className={classNames(containerStyles.container)}>
        <NearbyHotelCardSkeleton />
        <NearbyHotelCardSkeleton />
      </div>
    </div>
  )
}
