import styles from './HotelHeroMobile.module.scss'
import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Image } from 'src/ui/components/atoms/Image'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { Text } from 'src/ui/components/atoms/Text'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { HotelHeroMobileSkeleton } from 'src/ui/views/AvailableRooms/HotelHeroMobile/HotelHeroMobileSkeleton'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Divider, Skeleton } from 'src/ui/components'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { Flex } from 'src/ui/styles/objects/Flex'
import { BREAKPOINTS } from 'src/ui/styles/settings'
import { useHotelCheckInCheckoutTime } from 'src/ui/hooks/queries/useHotelCheckInCheckoutTime'
import { useModal } from 'src/ui/hooks/useModal'
import { HotelDetailModal } from 'src/ui/views/_components/HotelDetailModal'
import { container } from 'src/core/Shared/_di'

interface Props {
  hotel?: Hotel
}

export const HotelHeroMobile: FC<Props> = ({ hotel }) => {
  const { trans } = useTrans(['new-reservation'])
  const { availabilityCriteria } = useAvailability()
  const { hotelCheckInAndCheckOutTime, isLoadingCheckInAndCheckOut } =
    useHotelCheckInCheckoutTime()

  const { showModal: showHotelDetailModal } = useModal(HotelDetailModal)

  if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
    return <HotelHeroMobileSkeleton />
  }

  const hasDestination = !isEmpty(hotel.destination)

  const handleHotelDetailModal = () => {
    if (container.resolve('envManager').isProduction()) {
      return
    }
    return showHotelDetailModal({ hotel: hotel })
  }

  return (
    <section className={styles.hotelHeroContainer}>
      <div className={styles.hotelHeroImageContainer}>
        <Image
          alt={hotel.name}
          src={hotel.image}
          fill
          sizes={`(max-width: ${BREAKPOINTS.laptop.max}) 100vw`}
          className={styles.hotelHeroImage}
          quality={75}
          loader={getHotelImageLoader}
          priority={true}
          onClick={handleHotelDetailModal}
        />
      </div>
      <div className={styles.pill}>
        <div className={styles.hotelInfoContainer}>
          <Flex direction="column">
            <Text className={'mb-xxs'} fontStyle="m-700" color="dark">
              {hotel.name}
            </Text>
            {hasDestination && (
              <Text className="mb-xxs" fontStyle="s-300" color="dark">
                {hotel.destination}
              </Text>
            )}
            <TripadvisorRatings
              gap="small"
              reviews={hotel.reviews}
              reviewsMode="average"
            />
          </Flex>
          <div className={styles.stayInfoContainer}>
            <Flex direction="column">
              <Text fontStyle="s-500" color="dark">
                {trans('check-in_title')}
              </Text>
              {isLoadingCheckInAndCheckOut ||
              isUndefined(hotelCheckInAndCheckOutTime) ? (
                <Skeleton width={95} height={20} />
              ) : (
                <Text fontStyle="xs-300" color="mid">
                  {trans('check-in_after_short', {
                    checkIn: hotelCheckInAndCheckOutTime.checkInTime,
                  })}
                </Text>
              )}
            </Flex>
            <Divider dir="vertical" className={styles.checkInDivider} />
            <Flex direction="column">
              <Text fontStyle="s-500" color="dark">
                {trans('check-out_title')}
              </Text>
              {isLoadingCheckInAndCheckOut ||
              isUndefined(hotelCheckInAndCheckOutTime) ? (
                <Skeleton width={95} height={20} />
              ) : (
                <Text fontStyle="xs-300" color="mid">
                  {trans('check-out_before_short', {
                    checkOut: hotelCheckInAndCheckOutTime.checkOutTime,
                  })}
                </Text>
              )}
            </Flex>
          </div>
        </div>
      </div>
    </section>
  )
}
