import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type AverageDotsType = 'complete' | 'half' | 'empty'

export const getReviewsAverageDotsType = (
  ratings: string | undefined,
): AverageDotsType[] => {
  const parsedRatings =
    isUndefined(ratings) || isNaN(parseFloat(ratings)) ? 0 : parseFloat(ratings)
  const totalDots = 4
  const completeDots = Math.floor(parsedRatings)
  const hasHalfDots = parsedRatings - completeDots > 0
  const halfIndex = hasHalfDots ? completeDots + 1 : completeDots

  const dots = Array.from({ length: 5 })
    .fill('complete', 0, completeDots)
    .fill('half', completeDots, halfIndex)
    .fill('empty', halfIndex, totalDots + 1)

  return dots as AverageDotsType[]
}
