import { AvailableRooms } from './AvailableRooms'
import {
  AvailabilityRate,
  getHowCouponAppliesInRate,
  HowCouponAppliesInRate,
} from 'src/core/Availability/domain/Availability.model'
import { FC, useState } from 'react'
import { container } from 'src/core/Shared/_di'
import { mapCouponParam } from 'src/ui/views/AvailableRooms/mapCouponParam'
import {
  head,
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { OnRateSelected } from './AvailableRooms.model'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useCart } from 'src/ui/contexts/CartContext'
import { useDeleteObsoleteCart } from 'src/ui/views/AvailableRooms/useDeleteObsoleteCart'
import {
  appliesPromotionalCouponInReservation,
  Reservation,
  ReservedRate,
  SelectedMealplan,
  SelectedRate,
} from 'src/core/Reservation/domain/Reservation.model'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useAsyncMutation, useSyncMutation } from 'src/ui/hooks/useMutation'
import { useModal } from 'src/ui/hooks/useModal'
import { RateSelectorModal } from 'src/ui/views/AvailableRooms/RateSelectorModal'
import { routes } from 'src/ui/navigation/routes'
import { useRouter } from 'next/navigation'
import { useUser } from 'src/ui/contexts/UserContext'
import { useMealplanFiltersInUrl } from 'src/ui/views/AvailableRooms/useMealplanFiltersInUrl'
import { ReservationCriteria } from 'src/core/Reservation/domain/ReservationCriteria'
import {
  appliesCouponInRate,
  AvailabilityCoupon,
} from 'src/core/Availability/domain/AvailabilityCoupon'
import { useRoomTypeFiltersInUrl } from './useRoomTypeFiltersInUrl'

export const SingleRoomController: FC = () => {
  const { availabilityCoupon, howCouponAppliesInAvailability } = useCoupons()
  const { navigate, queryUtils } = useApplicationRouter()
  const router = useRouter()
  const currencyCode = queryUtils.getCurrencyParam()

  const [reservedRate, setReservedRate] = useState<ReservedRate | undefined>(
    undefined,
  )
  const [reservationInProgress, setReservationInProgress] = useState(false)
  const { cart } = useCart()
  const { showModal: showChangeRateModal, hideModal: hideChangeRateModal } =
    useModal(RateSelectorModal)

  const { isFiltering: isFilteringMealplan } = useMealplanFiltersInUrl()

  const {
    isFiltering: isFilteringRoomType,
    setFilter: setRoomTypeFilter,
    startFilterAll: startFilterAllRoomTypes,
    removeAllRoomTypeFilters,
    selectedFilter: selectedRoomTypeFilter,
  } = useRoomTypeFiltersInUrl()

  const { token } = useUser()

  const {
    availability,
    error: availabilityError,
    isValidating: isAvailabilityValidating,
    availabilityCriteria,
  } = useAvailability()

  const error =
    isDefined(availabilityError) && !isAvailabilityValidating
      ? availabilityError
      : undefined
  useDeleteObsoleteCart(cart, availabilityCriteria)

  const nextStep = async (reservation: Reservation) => {
    const promotionalCouponMustBeEliminated =
      !appliesPromotionalCouponInReservation(
        reservation,
        availabilityCoupon?.value,
      )
    await navigate.toExtrasFromAvailability(
      promotionalCouponMustBeEliminated,
      reservation.itineraryNumber ?? queryUtils.getRawParam('itineraryNumber'),
    )
  }

  const { performMutation: filterAvailabilityByRoomType } = useSyncMutation(
    'filterAvailabilityByRoomTypeSingleRoom',
    () => {
      return container.resolve('filterAvailabilityRoomsByRoomType')(
        availability?.stays[0],
        selectedRoomTypeFilter,
        removeAllRoomTypeFilters,
      )
    },
  )

  const createAndShowChangeRateModal = (
    coupon: AvailabilityCoupon,
    isMemberRate: boolean,
    selectedRate: AvailabilityRate,
    roomId: string,
    roomName: string,
    mealplan: SelectedMealplan,
    baseRate: AvailabilityRate | undefined,
    couponAppliesIn: HowCouponAppliesInRate['couponAppliesIn'],
  ) => {
    const onSecondaryButtonSelected = () => {
      function getRateToSelect() {
        if (couponAppliesIn === 'none') {
          return selectedRate
        }

        if (isMemberRate) {
          return selectedRate.relatedRate!.rate
        }

        return selectedRate
      }

      const rateToSelect = getRateToSelect()
      hideChangeRateModal()
      createReservation(roomId, roomName, mealplan, rateToSelect)
    }

    const onPrimaryButtonSelected = () => {
      hideChangeRateModal()
      setReservationInProgress(false)

      if (couponAppliesIn === 'none') {
        return
      }

      function getRateWhenSelectingMemberRate() {
        if (isMemberRate) {
          return selectedRate
        } else if (isDefined(baseRate)) {
          return baseRate
        } else {
          return selectedRate
        }
      }

      const rateToSelect = getRateWhenSelectingMemberRate()
      createReservation(roomId, roomName, mealplan, rateToSelect)
    }

    return showChangeRateModal({
      couponId: coupon.value,
      onSecondaryButtonSelected,
      onPrimaryButtonSelected,
      couponAppliesOnlyInStandardRate: couponAppliesIn === 'standardRate',
      couponAppliesOnlyInMemberRate: couponAppliesIn === 'memberRate',
      onClose: () => {
        setReservationInProgress(false)
      },
    })
  }

  const onSelectedRate: OnRateSelected = (
    roomId,
    roomName,
    mealplan,
    selectedRate,
  ) => {
    const continueWithRate = (rate: AvailabilityRate) => {
      return createReservation(roomId, roomName, mealplan, rate)
    }

    const isPromotionalCoupon =
      isDefined(availabilityCoupon) && availabilityCoupon.type === 'promotional'
    if (!isPromotionalCoupon) {
      return continueWithRate(selectedRate)
    }

    if (howCouponAppliesInAvailability === 'none') {
      return continueWithRate(selectedRate)
    }

    const { couponAppliesIn, is, baseRate } = getHowCouponAppliesInRate(
      roomName,
      mealplan,
      selectedRate,
      availability!,
    )

    if (couponAppliesIn === 'bothRates') {
      return continueWithRate(selectedRate)
    }

    const isMemberRate = is === 'memberRate'
    const couponAppliesOnlyInMemberRate = couponAppliesIn === 'memberRate'
    if (isMemberRate && couponAppliesOnlyInMemberRate) {
      return continueWithRate(selectedRate)
    }

    const isStandardRate = is === 'standardRate'
    const couponAppliesOnlyInStandardRate = couponAppliesIn === 'standardRate'
    if (isStandardRate && couponAppliesOnlyInStandardRate) {
      return continueWithRate(selectedRate)
    }

    return createAndShowChangeRateModal(
      availabilityCoupon,
      isMemberRate,
      selectedRate,
      roomId,
      roomName,
      mealplan,
      baseRate,
      couponAppliesIn,
    )
  }

  const createReservation: OnRateSelected = async (
    roomId,
    roomName,
    mealplan,
    rate,
  ) => {
    router.prefetch(routes.extras)
    if (isUndefined(availability) || isUndefined(availabilityCriteria)) {
      return
    }
    const { id: rateId } = rate

    setReservedRate({
      roomId,
      rateId,
    })

    const couponAppliesInRate =
      isDefined(availabilityCoupon) &&
      appliesCouponInRate(availabilityCoupon, rate)

    const originalTotalPrice = {
      grossPrice: rate.nonConvertedTotal.grossPrice,
      netPrice: rate.nonConvertedTotal.netPrice,
    }

    const reservationCriteria =
      ReservationCriteria.fromPrimitivesAndAvailabilityCriteria(
        availability.hotel.id,
        queryUtils.getMarketCampaign(),
        [
          {
            roomId,
            rateId,
            ...mapCouponParam({
              availabilityCoupon,
              promotionalCouponApplies: couponAppliesInRate,
            }),
            ...(isDefined(originalTotalPrice) && { originalTotalPrice }),
          },
        ],
        availabilityCriteria,
      )

    try {
      setReservationInProgress(true)
      const reservation = await container.resolve('createReservation')(
        reservationCriteria,
        token,
        currencyCode,
        availability,
        [{ roomName, mealplan, rateId }],
      )

      await nextStep(reservation)
    } catch (error) {
      setReservedRate(undefined)
      throw error
    } finally {
      setReservationInProgress(false)
    }
  }
  const { performMutation: handleSelectedRate } = useAsyncMutation(
    'handleSelectedRate',
    onSelectedRate,
  )

  const getSelectedRate = (): SelectedRate | undefined => {
    if (isUndefined(cart)) {
      return undefined
    }

    const { roomStays } = cart
    const roomStay = head(roomStays)

    if (isUndefined(roomStay)) {
      return undefined
    }

    const { room, rate } = roomStay

    return {
      roomId: room.id,
      mealplanId: rate.mealPlan.id,
      id: rate.id,
    }
  }

  const filteredAvailability = filterAvailabilityByRoomType()

  return (
    <AvailableRooms
      filteredAvailability={filteredAvailability}
      allRooms={availability?.stays[0].rooms}
      reservedRate={reservedRate}
      onRateSelected={handleSelectedRate}
      onFilterAllRoomType={startFilterAllRoomTypes}
      onFilterRoomType={setRoomTypeFilter}
      selectedRoomTypeFilter={selectedRoomTypeFilter}
      error={error}
      isAvailabilityLoading={
        isAvailabilityValidating || isFilteringMealplan || isFilteringRoomType
      }
      selectedRate={getSelectedRate()}
      isMultiroom={false}
      reservationInProgress={reservationInProgress}
    />
  )
}
