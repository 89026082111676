import styles from './HotelHeroDesktop.module.scss'
import { Divider, Skeleton, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  className?: string
}
export const HotelHeroDesktopSkeleton: FC<Props> = ({ className }) => {
  const { trans } = useTrans(['new-reservation'])
  return (
    <section
      className={classNames(className, styles.hotelHeroContainer)}
      data-testid="hotel-hero-desktop-skeleton"
    >
      <Skeleton
        width="100%"
        height={hotelHeroImageLaptopHeight}
        className={styles.hotelHeroImageSkeleton}
      />
      <div className={styles.accommodationContainer}>
        <Text className={styles.title} fontStyle="xs-300" color="mid">
          {trans('hotel-hero_your_accommodation')}
        </Text>
        <Skeleton className="mb-xs" width={250} height={20} />
        <Skeleton className="mb-xs" width={100} height={20} />
        <Skeleton className="mb-xs" width={250} height={20} />

        <Divider dir="horizontal" className="mt-m mb-m" />
        <Text className="mb-xs" fontStyle="xs-300" color="mid">
          {trans('hotel-hero_your_stay').toUpperCase()}
        </Text>
        <Skeleton className="mb-xs" width={150} height={20} />
        <Skeleton className="mb-m" width={125} height={20} />
      </div>
      <div className={styles.stayInfoContainer}>
        <Flex direction="column">
          <Skeleton className="mb-xss" width={115} height={40} />
        </Flex>
        <Divider dir="vertical" className={styles.divider} />
        <Flex direction="column">
          <Skeleton className="mb-xss" width={115} height={40} />
        </Flex>
      </div>
    </section>
  )
}

const hotelHeroImageLaptopHeight = 142
