import { FC } from 'react'
import { Price as PriceModel } from 'src/core/Shared/domain/Price.model'
import styles from './StrikethroughPrice.module.scss'
import { Price } from 'src/ui/components/molecules/Price'

export const StrikethroughPrice: FC<{
  grossPrice: PriceModel
  netPrice: PriceModel
}> = ({ grossPrice, netPrice }) => {
  return (
    <Price
      fontStyle="s"
      fontWeight="regular"
      className={styles.strikethrough}
      color="support-error"
      strikethrough
      grossPrice={grossPrice}
      netPrice={netPrice}
      round="off"
    />
  )
}
