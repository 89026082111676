import { FC } from 'react'

export const TripadvisorIcon: FC = () => (
  <>
    <path
      d="M12.7519 14.4962C13.579 16.827 16.2105 18.03 18.5414 17.1277C20.8722 16.3007 22.0752 13.6691 21.1729 11.3383C20.3459 9.00743 17.7143 7.80442 15.3835 8.70668C13.1278 9.53374 11.9248 12.1653 12.7519 14.4962Z"
      fill="white"
    />
    <path
      d="M7.03765 17.4286C9.52916 17.4286 11.5489 15.4088 11.5489 12.9173C11.5489 10.4258 9.52916 8.40601 7.03765 8.40601C4.54614 8.40601 2.52637 10.4258 2.52637 12.9173C2.52637 15.4088 4.54614 17.4286 7.03765 17.4286Z"
      fill="white"
    />
    <path
      d="M7.78951 12.8422C7.78951 13.2933 7.41357 13.6693 6.96244 13.6693C6.51132 13.6693 6.13538 13.2933 6.13538 12.8422C6.13538 12.3911 6.51132 12.0151 6.96244 12.0151C7.41357 12.0151 7.78951 12.3911 7.78951 12.8422Z"
      fill="#BE3538"
    />
    <path
      d="M17.7895 12.8422C17.7895 13.2933 17.4136 13.6693 16.9624 13.6693C16.5113 13.6693 16.1354 13.2933 16.1354 12.8422C16.1354 12.3911 16.5113 12.0151 16.9624 12.0151C17.4136 12.0151 17.7895 12.3911 17.7895 12.8422Z"
      fill="#589442"
    />
    <path
      d="M4.4812 12.842C4.4812 14.1954 5.60902 15.3232 6.9624 15.3232C8.31579 15.3232 9.44361 14.1954 9.44361 12.842C9.44361 11.4887 8.31579 10.3608 6.9624 10.3608C5.60902 10.3608 4.4812 11.4887 4.4812 12.842ZM5.30827 12.842C5.30827 11.9398 6.06015 11.1879 6.9624 11.1879C7.86466 11.1879 8.61654 11.9398 8.61654 12.842C8.61654 13.7443 7.86466 14.4962 6.9624 14.4962C6.06015 14.4962 5.30827 13.7443 5.30827 12.842ZM14.4812 12.842C14.4812 14.1954 15.609 15.3232 16.9624 15.3232C18.3158 15.3232 19.4436 14.1954 19.4436 12.842C19.4436 11.4887 18.3158 10.3608 16.9624 10.3608C15.609 10.3608 14.4812 11.4887 14.4812 12.842ZM15.3835 12.842C15.3835 11.9398 16.1353 11.1879 17.0376 11.1879C17.9398 11.1879 18.6917 11.9398 18.6917 12.842C18.6917 13.7443 17.9398 14.4962 17.0376 14.4962C16.0601 14.4962 15.3835 13.7443 15.3835 12.842Z"
      fill="#384044"
    />
    <path
      d="M15.2331 8.25573C15.7594 8.03017 16.2857 7.95498 16.812 7.95498C15.3835 7.27829 13.7293 6.97754 11.9248 6.97754C10.1203 6.97754 8.46617 7.27829 7.0376 7.95498C9.66918 8.03017 11.7744 10.0602 11.9248 12.6166C12 12.0151 12.0752 11.4136 12.3759 10.8873C12.9774 9.60912 14.0301 8.70686 15.2331 8.25573Z"
      fill="#FFD881"
    />
    <path
      d="M21.0226 9.90977C21.2481 8.93233 22 7.8797 22 7.8797H18.6917C16.812 6.67669 14.5564 6 12 6C9.36842 6 7.03759 6.67669 5.15789 7.8797L2 7.95489C2 7.95489 2.75188 8.93233 2.97744 9.98496C2.37594 10.812 2 11.7895 2 12.9173C2 15.6992 4.25564 17.9549 7.03759 17.9549C8.61654 17.9549 10.0451 17.203 10.9474 16.0752L12 17.6541L13.0526 16.0752C13.5038 16.6767 14.1805 17.203 14.8571 17.5038C16.0602 18.0301 17.4135 18.1053 18.6917 17.6541C21.2481 16.6767 22.6015 13.8195 21.6241 11.188C21.5489 10.7368 21.3233 10.2857 21.0226 9.90977ZM12 6.97744C13.8045 6.97744 15.4586 7.2782 16.8872 7.95489C16.3609 7.95489 15.8346 8.03008 15.3083 8.25564C14.0301 8.70677 13.0526 9.60902 12.5263 10.8872C12.3008 11.4135 12.1504 12.015 12.0752 12.6165C11.9248 10.0602 9.74436 8.03008 7.18797 7.95489C8.54135 7.2782 10.1955 6.97744 12 6.97744ZM10.3459 15.1729C9.59399 16.2256 8.39098 16.9023 7.03759 16.9023C4.85714 16.9023 3.05263 15.0977 3.05263 12.9173C3.05263 10.7368 4.85714 8.93233 7.03759 8.93233C9.21804 8.93233 11.0226 10.6617 11.0226 12.9173C11.0226 13.0677 11.0226 13.1429 10.9474 13.2932C10.8722 13.9699 10.7218 14.5714 10.3459 15.1729ZM18.391 16.6767C17.4135 17.0526 16.2857 16.9774 15.3083 16.5263C14.6316 16.2256 14.0301 15.6992 13.6541 15.0977C13.5038 14.8722 13.3534 14.5714 13.203 14.2707C13.0526 13.9699 13.0526 13.594 12.9774 13.2932C12.9023 12.6165 12.9774 11.8647 13.2782 11.2632C13.7293 10.2857 14.5564 9.53383 15.5338 9.15789C17.6391 8.40602 19.8947 9.45865 20.6466 11.4887C21.5489 13.594 20.4211 15.9248 18.391 16.6767Z"
      fill="#384044"
    />
  </>
)

export default TripadvisorIcon
