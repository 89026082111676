import { useState } from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { AvailabilityMealplanFilter } from 'src/core/Availability/domain/Availability.model'
import { container } from 'src/core/Shared/_di'

export function useMealplanFiltersInUrl() {
  const [isFiltering, setIsFiltering] = useState(false)
  const { queryUtils, navigate } = useApplicationRouter()

  const setFilter = async (selectedFilter: AvailabilityMealplanFilter) => {
    setIsFiltering(true)

    container
      .resolve('analytics')
      .actions.availableRooms.changeBoardBasis(selectedFilter)

    const query = queryUtils.buildMealplanFiltersQuery(selectedFilter)
    await navigate.toSameWithoutReload(query)
    setIsFiltering(false)
  }

  const removeAllMealplanFilters = async () => {
    const query = queryUtils.buildQueryWithoutMealplanFilters()
    await navigate.toSameWithoutReload(query)
    setIsFiltering(false)
  }

  const startFilterAll = () => {
    setIsFiltering(true)
    container.resolve('analytics').actions.availableRooms.changeBoardBasis()
    removeAllMealplanFilters()
  }

  return {
    isFiltering,
    setFilter,
    startFilterAll,
    removeAllMealplanFilters,
  }
}
