import {
  AvailabilityMealplan,
  AvailabilityMealplanId,
  AvailabilityRoom,
  AvailabilityStay,
} from 'src/core/Availability/domain/Availability.model'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import {
  isDefined,
  isUndefined,
  uniqBy,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const filterAvailabilityRoomsByMealplan =
  () =>
  (
    stay: AvailabilityStay | undefined,
    appliedFilter: AvailabilityMealplanId | undefined,
    onRemoveAllFilters: () => void,
    selectedCurrency?: CurrencyISOCode | undefined,
  ): AvailabilityStay | undefined => {
    if (isUndefined(stay)) {
      return undefined
    }

    const filteredStay = {
      ...stay,
      rooms: stay.rooms
        .map(room => ({
          ...room,
          mealplans: filterByCurrency(room, selectedCurrency).filter(mealplan =>
            byAppliedFilters(mealplan, appliedFilter),
          ),
        }))
        .filter(byRoomsWithoutMealplans),
      mealplanFilters: uniqBy(
        stay.rooms
          .map(room => ({
            ...room,
            mealplans: filterByCurrency(room, selectedCurrency),
          }))
          .filter(byRoomsWithoutMealplans)
          .flatMap(room => {
            return room.mealplans.map(mealplan => ({
              id: mealplan.id,
              name: mealplan.name,
            }))
          }),
        'id',
      ),
    }

    if (!doesHaveAvailabilityWithAppliedFilters(filteredStay, appliedFilter)) {
      onRemoveAllFilters()
    }

    return filteredStay
  }

const filterByCurrency = (
  room: AvailabilityRoom,
  selectedCurrency: CurrencyISOCode | undefined,
) =>
  room.mealplans
    .map(mealplan => ({
      ...mealplan,
      rates: isDefined(selectedCurrency)
        ? mealplan.rates.filter(
            rate =>
              rate.total.grossPrice.currency === selectedCurrency ||
              rate.total.netPrice.currency === selectedCurrency,
          )
        : mealplan.rates,
    }))
    .filter(byMealplansWithoutRates)

const byMealplansWithoutRates = (mealplan: AvailabilityMealplan) =>
  mealplan.rates.length > 0

const byRoomsWithoutMealplans = (room: AvailabilityRoom) =>
  room.mealplans.length > 0

const byAppliedFilters = (
  mealplan: AvailabilityMealplan,
  filter: AvailabilityMealplanId | undefined,
) => {
  if (isUndefined(filter)) {
    return true
  }

  const isMealplanInFilters = (mealplan: AvailabilityMealplan) =>
    mealplan.id === filter

  return isMealplanInFilters(mealplan)
}

const doesHaveAvailabilityWithAppliedFilters = (
  stays: AvailabilityStay,
  filter: AvailabilityMealplanId | undefined,
) => {
  if (isUndefined(filter)) {
    return true
  }

  return stays.mealplanFilters.some(
    mealplanFilter => mealplanFilter.id === filter,
  )
}
