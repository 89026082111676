import { ButtonSkeleton, Skeleton } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import cardStyles from './NearbyHotelCard.module.scss'
import footerStyles from './Footer/Footer.module.scss'
import styles from './NearbyHotelCardSkeleton.module.scss'
import { Media } from 'src/ui/styles/objects/Media'

export const NearbyHotelCardSkeleton = () => {
  return (
    <div className={cardStyles.hotelCardContainer}>
      <Flex>
        <div className={cardStyles.hotelImageContainer}>
          <Media laptop desktop>
            <Skeleton
              containerClassName={styles.container}
              width={146}
              height={222}
            />
          </Media>
          <Media tablet mobile>
            <Skeleton containerClassName={styles.container} height={222} />
          </Media>
        </div>
        <div className={cardStyles.hotelInfo}>
          <Media laptop desktop>
            <Flex direction="column" gap="xs">
              <Skeleton width={230} height={30} />
              <Skeleton width={196} height={24} />
              <Skeleton width={130} height={24} />
              <Skeleton width={250} height={24} />
              <Skeleton width={250} height={24} />
              <Skeleton width={250} height={24} />
            </Flex>
          </Media>
          <Media tablet mobile>
            <Flex direction="column" gap="xs">
              <Skeleton width={150} height={30} />
              <Skeleton width={96} height={24} />
              <Skeleton width={150} height={24} />
              <Skeleton width={170} height={24} />
              <Skeleton width={170} height={24} />
              <Skeleton width={170} height={24} />
            </Flex>
          </Media>
        </div>
      </Flex>
      <div className={footerStyles.footer}>
        <div>
          <Skeleton width={50} height={16} />
          <Skeleton width={92} height={30} />
        </div>
        <ButtonSkeleton width={130} height={54} />
      </div>
    </div>
  )
}
