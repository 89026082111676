import { FC } from 'react'
import styles from './Benefit.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'

interface Props {
  benefit: {
    title: string
    text: string
  }
}

export const Benefit: FC<Props> = ({ benefit }) => {
  return (
    <li className={styles.item}>
      <Flex direction="column" alignItems="center">
        <Text fontStyle="m-700" color="dark">
          {benefit.title}
        </Text>
        <Text fontStyle="s-300" color="dark">
          {benefit.text}
        </Text>
      </Flex>
    </li>
  )
}
