import { CSSProperties, FC } from 'react'
import styles from './Header.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { MyBarceloLogo } from 'src/ui/views/_components/MyBarceloLogo'
import { UserLevel } from 'src/core/User/domain/User'

interface Props {
  level: UserLevel
  color: string
}

export const Header: FC<Props> = ({ level, color }) => {
  const styleProps = {
    '--level-color': color,
  } as CSSProperties

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="m"
        className="mb-l"
      >
        <div className={styles.line} style={styleProps} />

        <MyBarceloLogo width={110} level={level} />

        <div className={styles.line} style={styleProps} />
      </Flex>
    </>
  )
}
