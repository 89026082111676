import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelRepository } from '../domain/Hotel.repository'
import { NearbyHotel } from '../domain/Hotel.model'
import { NearbyHotelsCriteria } from '../domain/NearbyHotelsCriteria'

export const getNearbyHotels =
  ({ hotelRepository }: Dependencies) =>
  async (request: NearbyHotelsCriteria): Promise<NearbyHotel[] | undefined> => {
    const nearbyHotels = await hotelRepository.getNearbyHotels(request)

    if (isUndefined(nearbyHotels) || isEmpty(nearbyHotels)) {
      return undefined
    }

    return nearbyHotels
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
