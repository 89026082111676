import { FC } from 'react'
import { Notification } from 'src/ui/components'
import { Translator, useTrans } from 'src/ui/hooks/useTrans'
import { NotificationTypes } from 'src/ui/components/molecules/Notification/NotificationTypes.model'
import { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'

interface Props {
  onClose: () => void
  coupon: AvailabilityCoupon
  closeAutomaticallyAfter?: number
}

export const GroupCodeNotification: FC<Props> = ({
  onClose,
  coupon,
  closeAutomaticallyAfter,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const title = getTitle(coupon, trans)
  const description = getDescription(coupon, trans)
  const type = getType(coupon)

  return (
    <Notification
      ariaLabel={`notification-${type}`}
      title={title}
      description={description}
      type={type}
      data-testid={`notification-icon-${type}`}
      data-quantum-warning={
        type === 'warning' ? 'INVALID_GROUP_COUPON' : undefined
      }
      isOpen={true}
      closeAutomaticallyAfter={closeAutomaticallyAfter}
      onClose={onClose}
    />
  )
}

const getTitle = (coupon: AvailabilityCoupon, trans: Translator) => {
  if (coupon.applies) {
    return trans(`coupon-notification-group_valid_title`, {
      couponId: coupon.value.trim(),
    })
  }

  return trans('coupon-notification_invalid_group-code_title')
}

const getDescription = (coupon: AvailabilityCoupon, trans: Translator) => {
  if (coupon.applies) {
    return trans('coupon-notification_valid_group-code_description')
  }

  return trans('coupon-notification_invalid_group-code_description', {
    couponId: coupon.value.trim(),
  })
}

const getType = (coupon: AvailabilityCoupon): NotificationTypes => {
  if (coupon.applies) {
    return 'success'
  }

  return 'warning'
}
