import { Image } from 'src/ui/components/atoms/Image'
import { IconText, Text } from 'src/ui/components'
import styles from './NearbyHotelCard.module.scss'
import { LocationIcon } from 'src/ui/components/atoms/Icon/_icons/general/LocationIcon'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Highlights } from './Highlights'
import { Footer } from './Footer'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { FC } from 'react'
import { Hotel, NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { BREAKPOINTS } from 'src/ui/styles/settings'

interface Props {
  id: NearbyHotel['id']
  bestPriceAverage: NearbyHotel['bestPriceAverage']
  hotel: Hotel | undefined
  onReserve: (hotelId: string) => void
}

export const NearbyHotelCard: FC<Props> = ({
  id,
  bestPriceAverage,
  hotel,
  onReserve,
}) => {
  if (isUndefined(hotel)) {
    return null
  }

  return (
    <div className={styles.hotelCardContainer}>
      <Flex alignItems="stretch">
        <div className={styles.hotelImageContainer}>
          <Image
            className={styles.hotelImage}
            alt={hotel.name}
            src={hotel.image}
            fill
            sizes={`(max-width: ${BREAKPOINTS.tablet.max}px) 33vw, (max-width: ${BREAKPOINTS.laptop.max}px) 14vw, (mix-width: ${BREAKPOINTS.desktop.min}px) 138px`}
            quality={75}
            loader={getHotelImageLoader}
          />
        </div>
        <div className={styles.hotelInfo}>
          <Flex direction="column" gap="xs">
            <Text
              as="h3"
              fontStyle="l-700"
              color="dark"
              className={styles.hotelName}
            >
              {hotel.name}
            </Text>
            <IconText
              icon={LocationIcon}
              iconSize="l"
              iconColor="icon-dark"
              text={hotel.destination}
              fontStyle="m-300"
              fontColor="dark"
              gap="xs"
              className={styles.hotelDestination}
            />
            <TripadvisorRatings
              gap="small"
              reviews={hotel.reviews}
              reviewsMode="average"
            />
          </Flex>
          {hotel.highlights && <Highlights highlights={hotel.highlights} />}
        </div>
      </Flex>
      <Footer
        bestPriceAverage={bestPriceAverage}
        id={id}
        onReserve={onReserve}
      />
    </div>
  )
}
