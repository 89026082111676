import { FC } from 'react'
import { IconText } from 'src/ui/components'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'

import styles from './Highlights.module.scss'
import { useMedia } from 'src/ui/hooks/useMedia'

interface Props {
  highlights: string[]
}

export const Highlights: FC<Props> = ({ highlights }) => {
  const { media } = useMedia()

  return (
    <div className={styles.highlightContainer}>
      {highlights.slice(0, 3).map(highlight => (
        <IconText
          key={highlight}
          className={styles.highlight}
          icon={CheckIcon}
          iconSize="s"
          iconColor="icon-dark"
          text={highlight}
          fontStyle="m-300"
          fontColor="dark"
          gap="xs"
          withEllipsis={media !== 'mobile'}
        />
      ))}
    </div>
  )
}
