import { FC, ReactNode } from 'react'
import mainLayoutStyles from '../layout.module.scss'
import styles from './AvailableRoomsLayout.module.scss'
import Head from 'next/head'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Header } from 'src/ui/views/_layouts/Header'
import { User } from 'src/core/User/domain/User'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { Footer } from 'src/ui/views/_layouts/Footer'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { FastBooking } from './FastBooking'
import { EditAvailabilityCriteriaMobile } from './EditAvailabilityCriteriaMobile'
import { useModal } from 'src/ui/hooks/useModal'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { AvailabilityError } from 'src/core/Availability/domain/AvailabilityError'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { Alert } from 'src/core/Hotel/domain/Alert.model'
import { Sanitizer } from 'src/ui/views/_components/Sanitizer'
import { BookingStepper } from 'src/ui/views/_components/BookingStepper'
import couponRibbonStyles from '../Header/PromotionalCouponRibbon/PromotionalCouponRibbon.module.scss' // Necesaria relativa para knip
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'
import { Media } from 'src/ui/styles/objects/Media'
import { FocusOn } from './AvailableRoomsLayout.model'

interface props {
  children: ReactNode
  onLogin: () => void
  onLogout: () => Promise<void>
  user: User | undefined
  hotel: Hotel | undefined
  hotelMarketingAlert: Alert | undefined
  showAlert: boolean
  onHideMarketingAlert: () => void
  onDatesSubmit: (dates: CheckInCheckOut) => void
  onGuestsSubmit: (guests: GuestsFormValues) => void
  onCouponsSubmit: (coupon: CouponsFormValues | undefined) => void
  onValidateCoupon: (coupon: string) => Promise<CouponValidationResult>
  onEditAvailabilityMobileModalSubmit: (
    dates: CheckInCheckOut,
    guests: GuestsFormValues,
    coupon: CouponsFormValues | undefined,
  ) => void
}

export const AvailableRoomsLayout: FC<props> = ({
  children,
  onLogin,
  user,
  hotel,
  onLogout,
  hotelMarketingAlert,
  onHideMarketingAlert,
  onDatesSubmit,
  onGuestsSubmit,
  onCouponsSubmit,
  onEditAvailabilityMobileModalSubmit,
  showAlert,
  onValidateCoupon,
}) => {
  const { trans } = useTrans(['pages'])
  const { availabilityCoupon } = useCoupons()
  const { availabilityCriteria, error } = useAvailability()
  const { showModal, hideModal } = useModal(
    EditAvailabilityCriteriaMobile,
    undefined,
    { mobile: true, tablet: true, laptop: false, desktop: false },
  )

  const handleClose = () => {
    hideModal()
  }

  const openModalWithExpanded = (focus: FocusOn) => {
    const hasEditAvailabilityModalRequiredProps =
      isDefined(availabilityCriteria) &&
      isDefined(hotel) &&
      isDefined(hotel.guestOptions)

    if (!hasEditAvailabilityModalRequiredProps) {
      return
    }

    showModal({
      adultsList: availabilityCriteria.adults,
      childrenList: availabilityCriteria.children,
      childrenAges: availabilityCriteria.childrenAges,
      focus: focus,
      checkIn: availabilityCriteria.checkIn,
      checkOut: availabilityCriteria.checkOut,
      maxGuests: hotel.guestOptions.maxGuests,
      maxChildren: hotel.guestOptions.maxChildren,
      maxAdults: hotel.guestOptions.maxAdults,
      maxChildAge: hotel.guestOptions.maxChildAge,
      showChildren: !hotel.isAdultsOnly,
      onClose: handleClose,
      setFocus: focus => openModalWithExpanded(focus),
      coupon: calculateCoupon(),
      onSubmit: onEditAvailabilityMobileModalSubmit,
      user: user,
      initialDatesError: hasHotelAvailabilityNotFoundError,
      onValidateCoupon: onValidateCoupon,
    })
  }

  const calculateCoupon = () => {
    if (availabilityCoupon?.type === 'promotional') {
      return availabilityCoupon
    }

    return availabilityCoupon?.applies ? availabilityCoupon : undefined
  }

  const hasHotelAvailabilityNotFoundError =
    AvailabilityError.isHotelAvailabilityNotFoundError(error)

  return (
    <>
      <div id="coupon-ribbon-root" className={couponRibbonStyles.container} />

      <Head>
        <title>{trans('available-rooms-layout_page-title')}</title>
        <meta
          property="og:title"
          content={trans('available-rooms-layout_page-title')}
          key="AvailableRoomsBookingEngine"
        />
      </Head>
      {isDefined(hotelMarketingAlert) && showAlert && (
        <Flex
          className={styles.marketingAlert}
          justifyContent="space-between"
          alignItems="center"
          gap="xs"
        >
          <Text fontStyle="s-700" className={styles.alertText}>
            <Sanitizer htmlText={hotelMarketingAlert.description} />
          </Text>
          <button
            className={styles.exitButton}
            onClick={onHideMarketingAlert}
            aria-label={trans('alert_close-button_aria-label')}
          >
            <Icon size="l" color="text-dark" icon={CloseIcon} />
          </button>
        </Flex>
      )}
      <div className={mainLayoutStyles.appContainer}>
        <Header
          onLogin={onLogin}
          onLogout={onLogout}
          user={user}
          fastBooking={
            <FastBooking
              dates={
                isDefined(availabilityCriteria)
                  ? {
                      checkIn: availabilityCriteria.checkIn,
                      checkOut: availabilityCriteria.checkOut,
                    }
                  : undefined
              }
              guests={{
                adults: availabilityCriteria?.adults,
                children: availabilityCriteria?.children,
                childrenAges: availabilityCriteria?.childrenAges,
              }}
              hotel={hotel}
              onDatesSubmit={onDatesSubmit}
              onGuestsSubmit={onGuestsSubmit}
              onCouponsSubmit={onCouponsSubmit}
              onOpenAllWithDatesExpanded={() => openModalWithExpanded('dates')}
              onOpenAllWithOccupancyExpanded={() =>
                openModalWithExpanded('occupancy')
              }
              onOpenAllWithCouponsExpanded={() =>
                openModalWithExpanded('coupons')
              }
              initialErrorOnDates={hasHotelAvailabilityNotFoundError}
              onValidateCoupon={onValidateCoupon}
            />
          }
        />
        <Media tablet laptop desktop>
          <div className={styles.bookingStepperWrapper}>
            <BookingStepper currentStep={1} />
          </div>
        </Media>
        <main
          className={mainLayoutStyles.mainContainer}
          data-target-view="available-rooms"
        >
          {children}
        </main>
        <Footer />
      </div>
      <div id="stays-summary-root" className={styles.staysSummaryRoot} />
    </>
  )
}
