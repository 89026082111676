import styles from './IconText.module.scss'

import { CSSProperties, FC } from 'react'

import { Color, IconSize, SpaceType } from 'src/ui/styles/settings'

import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { classNames } from 'src/ui/utils/classnames'
import {
  Skeleton,
  TextLineHeight,
} from 'src/ui/components/atoms/Skeleton/Skeleton'

interface Props {
  icon: FC<{
    color: string
  }>
  iconSize: IconSize
  iconColor: Color
  gap: SpaceType
  className?: string
  textLineHeight: TextLineHeight
  textWidth?: number
}

export const IconTextSkeleton: FC<Props> = ({
  icon,
  iconSize,
  iconColor,
  gap = 0,
  className,
  textLineHeight,
  textWidth = 130,
}) => {
  const styleProps = {
    gap: gap && `var(--b-space-${gap})`,
  } as CSSProperties

  return (
    <span
      className={classNames(styles.container, className)}
      style={styleProps}
    >
      <Icon icon={icon} size={iconSize} color={iconColor} />
      <Skeleton textLineHeight={textLineHeight} width={textWidth} />
    </span>
  )
}
