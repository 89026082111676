import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { MultiRoomController } from './MultiRoom.controller'
import { SingleRoomController } from './SingleRoom.controller'
import { isMultiroomFromPrimitives } from 'src/core/Availability/domain/AvailabilityCriteria'
import { useAnalyticsPageView } from 'src/ui/hooks/useAnalyticsPageView'
import { useSetQueryParamDefaultsWhenError } from './useSetQueryParamDefaultsWhenError'
import { QueryParams } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'
import { QueryParamKey } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'

export const AvailableRoomsController = () => {
  useAnalyticsPageView('availableRooms')
  const { errors } = useAvailabilityCriteria()
  useSetQueryParamDefaultsWhenError(errors)
  const { getRawParams } = useApplicationRouter()

  return isMultiRoom(getRawParams) ? (
    <MultiRoomController />
  ) : (
    <SingleRoomController />
  )
}

function isMultiRoom(
  getRawParams: (params: QueryParamKey[]) => QueryParams | undefined,
) {
  const roomsParam = getRawParams(['rooms', 'adult'])

  return isMultiroomFromPrimitives({
    rooms: roomsParam?.rooms,
    adults: roomsParam?.adult,
  })
}
