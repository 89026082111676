import styles from './HotelHeroDesktop.module.scss'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Image } from 'src/ui/components/atoms/Image'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { Text } from 'src/ui/components/atoms/Text'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { HotelHeroDesktopSkeleton } from './HotelHeroDesktopSkeleton'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Divider, Skeleton } from 'src/ui/components'
import { getGuestsResumeTitle } from 'src/ui/i18n/getGuestsResumeTitle'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { Flex } from 'src/ui/styles/objects/Flex'
import { classNames } from 'src/ui/utils/classnames'
import { useHotelCheckInCheckoutTime } from 'src/ui/hooks/queries/useHotelCheckInCheckoutTime'
import { useModal } from 'src/ui/hooks/useModal'
import { HotelDetailModal } from 'src/ui/views/_components/HotelDetailModal'
import { container } from 'src/core/Shared/_di'

interface Props {
  className?: string
  hotel?: Hotel
}

export const HotelHeroDesktop: FC<Props> = ({ hotel, className }) => {
  const { trans } = useTrans(['new-reservation'])
  const { availabilityCriteria } = useAvailability()
  const { hotelCheckInAndCheckOutTime, isLoadingCheckInAndCheckOut } =
    useHotelCheckInCheckoutTime()

  const { showModal: showHotelDetailModal } = useModal(HotelDetailModal)

  if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
    return <HotelHeroDesktopSkeleton className="mb-m" />
  }

  const hasDestination = !isEmpty(hotel.destination)
  const hasAverageRate = isDefined(hotel.reviews.averageRate)
  const hasToShowHotelDetails = hasDestination || hasAverageRate
  const occupancy = getGuestsResumeTitle({
    adultsList: availabilityCriteria.adults,
    childrenList: availabilityCriteria.children,
    trans,
    showRooms: true,
    showRoomsFirst: true,
  })

  const handleHotelDetailModal = () => {
    if (container.resolve('envManager').isProduction()) {
      return
    }
    return showHotelDetailModal({ hotel: hotel })
  }

  return (
    <section className={classNames(className, styles.hotelHeroContainer)}>
      <Image
        alt={hotel.name}
        src={hotel.image}
        aria-hidden="true"
        height={hotelHeroImageDesktopHeight}
        width={hotelHeroImageDesktopHeight * hotelHeroImageAspectRatio}
        className={classNames(
          styles.hotelImageTallScreen,
          styles.hideOnShortScreen,
        )}
        quality={75}
        loader={getHotelImageLoader}
        priority={true}
        onClick={handleHotelDetailModal}
      />
      <Flex
        className={classNames(
          styles.heroContainerShortScreen,
          styles.hideOnTallScreen,
        )}
        gap="xs"
      >
        <Image
          alt={hotel.name}
          src={hotel.image}
          height={64 * 3}
          width={64 * 3}
          className={styles.hotelImageShortScreen}
          quality={75}
          loader={getHotelImageLoader}
          priority={true}
        />
        <Text className="self-center" fontStyle="m-700" color="dark">
          {hotel.name}
        </Text>
      </Flex>
      <div className={styles.accommodationContainer}>
        <Text
          className={classNames(styles.title, styles.hideOnShortScreen)}
          fontStyle="xs-300"
          color="mid"
        >
          {trans('hotel-hero_your_accommodation')}
        </Text>
        <Text
          className={classNames(styles.hideOnShortScreen, 'mb-xs')}
          fontStyle="m-700"
          color="dark"
          aria-hidden="true"
        >
          {hotel.name}
        </Text>
        {hasToShowHotelDetails && (
          <>
            {hasDestination && (
              <Text className="mb-xs" fontStyle="s-300" color="dark">
                {hotel.destination}
              </Text>
            )}
            <TripadvisorRatings
              gap="small"
              reviews={hotel.reviews}
              reviewsMode="average"
            />
          </>
        )}
        <Divider dir="horizontal" className={styles.divider} />
        <Text className={styles.title} fontStyle="xs-300" color="mid">
          {trans('hotel-hero_your_stay')}
        </Text>
        <Text className="mb-xs" fontStyle="s-300" color="dark">
          {occupancy}
        </Text>
        <Text fontStyle="s-700" color="dark">
          {datesManager.formatDateRangeToLocaleWithYear(
            {
              checkIn: availabilityCriteria.checkIn,
              checkOut: availabilityCriteria.checkOut,
            },
            'long',
          )}
        </Text>
      </div>
      <div className={styles.stayInfoContainer}>
        <Flex direction="column">
          <Text className="mb-xxs" fontStyle="s-500" color="dark">
            {trans('check-in_title')}
          </Text>
          {isLoadingCheckInAndCheckOut ||
          isUndefined(hotelCheckInAndCheckOutTime) ? (
            <Skeleton width={95} height={15} />
          ) : (
            <Text className="mb-xs" fontStyle="xs-300" color="mid">
              {trans('check-in_after_short', {
                checkIn: hotelCheckInAndCheckOutTime.checkInTime,
              })}
            </Text>
          )}
        </Flex>
        <Divider dir="vertical" className={styles.checkInDivider} />
        <Flex direction="column">
          <Text className="mb-xxs" fontStyle="s-500" color="dark">
            {trans('check-out_title')}
          </Text>
          {isLoadingCheckInAndCheckOut ||
          isUndefined(hotelCheckInAndCheckOutTime) ? (
            <Skeleton width={95} height={15} />
          ) : (
            <Text fontStyle="xs-300" color="mid">
              {trans('check-out_before_short', {
                checkOut: hotelCheckInAndCheckOutTime.checkOutTime,
              })}
            </Text>
          )}
        </Flex>
      </div>
    </section>
  )
}

const hotelHeroImageDesktopHeight = 142
const hotelHeroImageAspectRatio = 2.0440140845
