import { classNames } from 'src/ui/utils/classnames'
import styles from './Pill.module.scss'
import { ReactNode, FC } from 'react'

interface Props {
  expandedComponent: ReactNode
  hasError: boolean
  focused: boolean
  onOpen: () => void
  collapsedComponent: ReactNode
}

export const Pill: FC<Props> = ({
  expandedComponent,
  hasError,
  focused,
  onOpen,
  collapsedComponent,
}) => {
  return (
    <div
      className={classNames(
        styles.pill,
        hasError && styles.pill_error,
        focused && styles.expandedPill,
      )}
    >
      {focused ? (
        expandedComponent
      ) : (
        <button
          className={classNames(styles.collapsedContainer)}
          onClick={onOpen}
        >
          {collapsedComponent}
        </button>
      )}
    </div>
  )
}
