import { FC } from 'react'

export const BComIcon: FC = () => (
  <>
    <circle cx="12.5" cy="12" r="12" fill="url(#paint0_linear_4429_53962)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2291 11.4967C17.1742 10.9216 17.6347 10.093 17.6347 8.97318C17.6347 6.83343 16.3452 5.79358 13.6922 5.79358H8.08861C8.01136 5.79358 7.94873 5.85779 7.94873 5.93729V18.1835C7.94873 18.263 8.01136 18.3274 8.08861 18.3274H13.8592C15.8622 18.3274 18.2018 17.3599 18.2018 14.633C18.2018 13.0946 17.5044 11.9931 16.2291 11.4967ZM14.8197 9.43639C14.8197 10.5223 14.0251 10.7503 13.3582 10.7503H10.8465V8.17355H13.1581C14.2918 8.17355 14.8197 8.57477 14.8197 9.43639ZM15.3033 14.4794C15.3033 15.4674 14.7163 15.9477 13.5086 15.9477H10.8465V12.8738H13.5586C14.7326 12.8738 15.3033 13.3989 15.3033 14.4794Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4429_53962"
        x1="0.5"
        y1="11.8588"
        x2="24.5"
        y2="11.8588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30A2BF" />
        <stop offset="1" stopColor="#2F5A76" />
      </linearGradient>
    </defs>
  </>
)

export default BComIcon
