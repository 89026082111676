import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter'
import { QueryParamsErrors } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'
import { useEffect } from 'react'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { location } from 'src/ui/navigation/location'

export const useSetQueryParamDefaultsWhenError = (
  errors: QueryParamsErrors | undefined,
) => {
  const { navigate, isRouterReady, queryUtils, getRawParams } =
    useApplicationRouter()

  const params = getRawParams(['arrive', 'depart'])

  useEffect(() => {
    if (!isRouterReady || isUndefined(errors)) {
      return
    }

    if (errors.hasHotelError) {
      location.toHotelList(params?.arrive, params?.depart)
      return
    }

    const {
      hasGuestError,
      hasDatesError,
      hasMarketPriceError,
      hasCurrencyError,
    } = errors

    navigate.toAvailabilityWithDefaultsForErrors({
      hasGuestError,
      hasDatesError,
      hasMarketPriceError,
      hasCurrencyError,
    })
  }, [
    errors,
    navigate,
    isRouterReady,
    queryUtils,
    params?.arrive,
    params?.depart,
  ])
}
