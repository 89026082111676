import styles from './HotelHeroMobile.module.scss'
import { Divider, Skeleton, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'

export const HotelHeroMobileSkeleton = () => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <div className={styles.hotelHeroContainer}>
      <div className={styles.hotelHeroImageSkeletonContainer}>
        <Skeleton
          width="100%"
          height={hotelHeroImageLaptopHeight}
          className={styles.hotelHeroImageSkeleton}
        />
      </div>
      <div className={styles.pill}>
        <div className={styles.skeletonContainer}>
          <Skeleton className="mb-xxs" width={250} height={24} />
          <Skeleton className="mb-xxs" width={250} height={20} />
          <Skeleton className="mb-xxs" width={102} height={24} />
          <Flex direction="row">
            <Flex direction="column">
              <Text fontStyle="s-500" color="dark">
                {trans('check-in_title')}
              </Text>
              <Skeleton width={100} height={24} />
            </Flex>
            <Divider dir="vertical" className={styles.checkInDivider} />
            <Flex direction="column">
              <Text fontStyle="s-500" color="dark">
                {trans('check-out_title')}
              </Text>
              <Skeleton width={100} height={24} />
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  )
}

const hotelHeroImageLaptopHeight = 200
