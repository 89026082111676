import { GetServerSideProps } from 'next'
import { CustomNextPage } from './_app'

import { ReactElement } from 'react'
import { AvailableRoomsLayout } from 'src/ui/views/_layouts/AvailableRooms'
import { AvailableRooms } from 'src/ui/views/AvailableRooms'
import { CartProvider } from 'src/ui/contexts/CartContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CouponsProvider } from 'src/ui/contexts/CouponsContext'
import { AvailabilityProvider } from 'src/ui/contexts/AvailabilityContext'

const AvailableRoomsPage: CustomNextPage = () => {
  return <AvailableRooms />
}

export default AvailableRoomsPage

AvailableRoomsPage.getLayout = function GetLayout(
  availableRooms: ReactElement,
) {
  useTrans(['pages'])
  const { queryUtils } = useApplicationRouter()

  const removeItineraryNumber = async () => {
    await queryUtils.removeParam('itineraryNumber')
  }

  return (
    <CartProvider onDeletedCart={removeItineraryNumber}>
      <AvailabilityProvider>
        <CouponsProvider>
          <AvailableRoomsLayout>{availableRooms}</AvailableRoomsLayout>
        </CouponsProvider>
      </AvailabilityProvider>
    </CartProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  }
}
