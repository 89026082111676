import { FC } from 'react'

interface Props {
  color: string
}

export const ClockIcon: FC<Props> = ({ color }) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.9997 3.50488C7.30527 3.50488 3.49969 7.31046 3.49969 12.0049C3.49969 16.6993 7.30527 20.5049 11.9997 20.5049C16.6941 20.5049 20.4997 16.6993 20.4997 12.0049C20.4997 7.31046 16.6941 3.50488 11.9997 3.50488ZM2.49969 12.0049C2.49969 6.75818 6.75299 2.50488 11.9997 2.50488C17.2464 2.50488 21.4997 6.75818 21.4997 12.0049C21.4997 17.2516 17.2464 21.5049 11.9997 21.5049C6.75299 21.5049 2.49969 17.2516 2.49969 12.0049ZM11.9997 8.29053C12.2758 8.29053 12.4997 8.51438 12.4997 8.79053V11.7978L16.3707 15.6697C16.566 15.8649 16.5659 16.1815 16.3706 16.3768C16.1754 16.572 15.8588 16.572 15.6635 16.3767L11.6461 12.3584C11.5905 12.3028 11.5508 12.2374 11.5268 12.1677C11.5119 12.1244 11.5028 12.0783 11.5003 12.0305C11.4999 12.022 11.4997 12.0134 11.4997 12.0048C11.4997 12.0047 11.4997 12.0046 11.4997 12.0045V8.79053C11.4997 8.51438 11.7236 8.29053 11.9997 8.29053Z"
    fill={color}
  />
)

export default ClockIcon
