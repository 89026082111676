import { FC, FormEvent, useRef, RefObject } from 'react'
import { FormProvider } from 'react-hook-form'

import { Button, Divider, Dropdown, Text } from 'src/ui/components'

import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { useGuestsForm, GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Translate } from 'src/ui/i18n/Translate'
import { GuestsForm } from 'src/ui/views/_components/GuestsForm'
import styles from './GuestsDropdownContent.module.scss'

interface Props {
  adultsList: number[]
  childrenList: number[]
  childrenAges?: Array<Array<number>>
  maxGuests: number
  maxChildren: number
  maxAdults: number
  maxChildAge: number
  showChildren: boolean
  showDropdown: boolean
  onHideDropdown: () => void
  onSubmit: (guests: GuestsFormValues) => void
  triggerRef?: RefObject<HTMLButtonElement>
}

export const GuestsDropdownContent: FC<Props> = ({
  adultsList,
  childrenList,
  childrenAges,
  maxGuests,
  maxChildren,
  maxAdults,
  maxChildAge,
  showChildren,
  showDropdown,
  onHideDropdown,
  triggerRef,
  onSubmit,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const titleRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const {
    methods,
    expandedRoomId,
    uniqueIds,
    canAddMoreRooms,
    hasErrors,
    validateFields,
    resetForm,
    handleAddRoom,
    handleDeleteRoom,
    handleOpenRoom,
  } = useGuestsForm({
    adultsList,
    childrenList,
    childrenAges,
  })

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    validateFields(onSubmit, onHideDropdown)
  }

  const hideDropdown = () => {
    resetForm()
    onHideDropdown()
  }

  if (!showDropdown) {
    return null
  }

  return (
    <Dropdown
      triggerRef={triggerRef}
      className={styles.dropdown}
      show={showDropdown}
      blockBodyScroll
      onClickOutside={hideDropdown}
    >
      <div className={styles.guestsTitle} ref={titleRef}>
        <Text fontStyle="xl-700" color="dark">
          {trans('occupancy-form_guests_main-title')}
        </Text>
      </div>
      <FormProvider {...methods}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} noValidate>
            {uniqueIds.map((uniqueId, roomIndex) => (
              <div key={uniqueId}>
                <GuestsForm
                  maxGuests={maxGuests}
                  maxChildren={maxChildren}
                  maxAdults={maxAdults}
                  maxChildAge={maxChildAge}
                  showChildren={showChildren}
                  roomIndex={roomIndex}
                  roomUniqueId={uniqueId}
                  numberOfUniqueIds={uniqueIds.length}
                  expandedRoomId={expandedRoomId}
                  hasErrors={hasErrors}
                  onDeleteRoom={handleDeleteRoom}
                  onOpenRoom={handleOpenRoom}
                />
                {roomIndex < uniqueIds.length - 1 && (
                  <Divider dir="horizontal" />
                )}
              </div>
            ))}
            {!canAddMoreRooms && (
              <Translate
                i18nKey="new-reservation:occupancy-form_guests_add-room_max-rooms"
                values={{ phone: `${callCenterPhone}${callCenterFreePhone}` }}
                components={{
                  regular: (
                    <Text
                      className="mt-xl"
                      as="p"
                      fontStyle="m-300"
                      color="mid"
                    />
                  ),
                  bold: (
                    <Text
                      data-data-phone={callCenterCountryCode}
                      className={styles.phone}
                      fontStyle="link"
                      color="mid"
                    />
                  ),
                }}
              />
            )}
          </form>
        </div>
        <div className={styles.formButtonContainer} ref={footerRef}>
          {canAddMoreRooms && (
            <Button
              variant="secondary"
              className={styles.addRoomButton}
              onClick={e => {
                e.preventDefault()
                handleAddRoom()
              }}
            >
              {trans('occupancy-form_add-room')}
            </Button>
          )}
          <Button
            width="fit-content"
            type="submit"
            size="large"
            onClick={handleSubmit}
          >
            {trans('occupancy-form_accept')}
          </Button>
        </div>
      </FormProvider>
    </Dropdown>
  )
}
