import { FC } from 'react'
import styles from './InputRadio.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  isSelected: boolean
  name: string
  id: string
  isDisabled?: boolean
  withTag?: boolean
  children: React.ReactNode
  onChange: () => void
}

export const InputRadio: FC<Props> = ({
  children,
  isSelected,
  name,
  id,
  isDisabled = false,
  withTag = false,
  onChange,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        isSelected && styles.isSelected,
        isDisabled && styles.isDisabled,
        withTag && styles.withTag,
      )}
      {...rest}
    >
      <input
        type="radio"
        name={name}
        id={id}
        checked={isSelected}
        onChange={onChange}
        className={styles.inputRadio}
        disabled={isDisabled}
        data-testid={id}
      />
      <label className={styles.label} htmlFor={id}>
        {children}
      </label>
    </div>
  )
}
