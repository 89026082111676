import { FC } from 'react'
import { AvailabilityRate } from 'src/core/Availability/domain/Availability.model'
import { Media } from 'src/ui/styles/objects/Media'
import { CurrentRatePriceMobile } from './CurrentRatePriceMobile'
import { CurrentRatePriceDesktop } from './CurrentRatePriceDesktop'

type Props = {
  rate: AvailabilityRate
  isOneNight: boolean
  howCouponApplies: 'both' | 'some' | undefined
}

export const CurrentRatePrice: FC<Props> = ({
  rate,
  isOneNight,
  howCouponApplies,
}) => {
  return (
    <>
      <Media mobile tablet>
        <CurrentRatePriceMobile
          rate={rate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies}
        />
      </Media>

      <Media laptop desktop>
        <CurrentRatePriceDesktop
          rate={rate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies}
        />
      </Media>
    </>
  )
}
