import { asFunction } from 'awilix'
import { container } from 'src/core/Shared/_di'
import { filterAvailabilityRoomsByRoomType } from 'src/core/Availability/usecases/filterAvailabilityRoomsByRoomType'
import { filterAvailabilityRoomsByMealplan } from 'src/core/Availability/usecases/filterAvailabilityRoomsByMealplan'
import { getAllAvailability } from 'src/core/Availability/usecases/getAllAvailability'
import { getAvailabilityByGroupCoupon } from 'src/core/Availability/usecases/getAvailabilityByGroupCoupon'
import { apiAvailabilityRepository } from 'src/core/Availability/infrastructure/Availability.api.repository'
import { create } from 'src/core/Reservation/usecases/create'
import { deleteObsoleteCart } from 'src/core/Cart/usecases/deleteObsoleteCart'
import { logout } from 'src/core/User/usecases/logout'
import { login } from 'src/core/User/usecases/login'
import { getCartWhenIsDifferent } from 'src/core/Cart/usecases/getCartWhenIsDifferent'
import { getHotelById } from 'src/core/Hotel/usecases/getHotelById'
import { getHotelMarketingAlert } from 'src/core/Hotel/usecases/getHotelMarketingAlert'
import { getHotelPromotions } from 'src/core/Hotel/usecases/getHotelPromotions'
import { getNearbyHotels } from 'src/core/Hotel/usecases/getNearbyHotels'
import { getPromotionalValidCoupon } from 'src/core/Hotel/usecases/getPromotionalValidCoupon'
import { getCouponValidation } from 'src/core/Hotel/usecases/getCouponValidation'
import { getViewingNowAccelerators } from 'src/core/Reservation/usecases/getViewingNowAccelerators'

const modules = {
  availabilityRepository: asFunction(apiAvailabilityRepository),
  getAllAvailability: asFunction(getAllAvailability),
  getAvailabilityByGroupCoupon: asFunction(getAvailabilityByGroupCoupon),
  filterAvailabilityRoomsByMealplan: asFunction(
    filterAvailabilityRoomsByMealplan,
  ),
  filterAvailabilityRoomsByRoomType: asFunction(
    filterAvailabilityRoomsByRoomType,
  ),
  createReservation: asFunction(create),
  deleteObsoleteCart: asFunction(deleteObsoleteCart),
  login: asFunction(login),
  logout: asFunction(logout),
  getHotelById: asFunction(getHotelById),
  getHotelMarketingAlert: asFunction(getHotelMarketingAlert),
  getHotelPromotions: asFunction(getHotelPromotions),
  getCartWhenIsDifferent: asFunction(getCartWhenIsDifferent),
  getCouponValidation: asFunction(getCouponValidation),
  getPromotionalValidCoupon: asFunction(getPromotionalValidCoupon),
  getNearbyHotels: asFunction(getNearbyHotels),
  getViewingNowAccelerators: asFunction(getViewingNowAccelerators),
}

container.register(modules)
