import styles from './BannerBCom.module.scss'
import { FC } from 'react'
import { Promotion } from 'src/core/Hotel/domain/Promotion.model'
import { HotelPromotions } from 'src/ui/views/AvailableRooms/HotelPromotions'
import { HotelPromotionsHeader } from 'src/ui/views/AvailableRooms/HotelPromotionsHeader'
import { useTrans } from 'src/ui/hooks/useTrans'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  hotelPromotions: Promotion[]
  isLoadingPromotions: boolean
  onMoreInfoClick: (hotelPromotions: Promotion[]) => void
}
export const BannerBCom: FC<Props> = ({
  hotelPromotions,
  isLoadingPromotions,
  onMoreInfoClick,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <div className={styles.container}>
      <HotelPromotionsHeader />
      <HotelPromotions
        hotelPromotions={hotelPromotions}
        isLoadingPromotions={isLoadingPromotions}
      />

      <ButtonLink
        className="w-fit"
        aria-label={trans('available-rooms_banner-advantages_view_more')}
        onClick={() => onMoreInfoClick(hotelPromotions)}
      >
        {trans('available-rooms_banner-advantages_view_more')}
      </ButtonLink>
    </div>
  )
}
