import {
  AvailabilityMealplan,
  AvailabilityRoom,
  AvailabilityRoomTypeFilter,
  AvailabilityStay,
} from 'src/core/Availability/domain/Availability.model'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const filterAvailabilityRoomsByRoomType =
  () =>
  (
    stay: AvailabilityStay | undefined,
    appliedFilter: AvailabilityRoomTypeFilter | undefined,
    onRemoveAllFilters: () => void,
    selectedCurrency?: CurrencyISOCode | undefined,
  ): AvailabilityStay | undefined => {
    if (isUndefined(stay)) {
      return undefined
    }

    const filteredStay = {
      ...stay,
      rooms: stay.rooms
        .filter(room => {
          if (isUndefined(appliedFilter)) {
            return true
          }
          return room.id === appliedFilter
        })
        .map(room => ({
          ...room,
          mealplans: filterByCurrency(room, selectedCurrency),
        }))
        .filter(room => {
          return room.mealplans.length > 0
        }),
    }

    if (!hasAnyRoomInStay(filteredStay)) {
      onRemoveAllFilters()
    }

    return filteredStay
  }

const filterByCurrency = (
  room: AvailabilityRoom,
  selectedCurrency: CurrencyISOCode | undefined,
) =>
  room.mealplans
    .map(mealplan => ({
      ...mealplan,
      rates: isDefined(selectedCurrency)
        ? mealplan.rates.filter(
            rate =>
              rate.total.grossPrice.currency === selectedCurrency ||
              rate.total.netPrice.currency === selectedCurrency,
          )
        : mealplan.rates,
    }))
    .filter(byMealplansWithoutRates)

const byMealplansWithoutRates = (mealplan: AvailabilityMealplan) => {
  return mealplan.rates.length > 0
}

const hasAnyRoomInStay = (stay: AvailabilityStay) => {
  return stay.rooms.length > 0
}
