import { FC } from 'react'
import styles from './Header.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { MyBarceloLogo } from 'src/ui/views/_components/MyBarceloLogo'

interface Props {
  color: string
}

export const Header: FC<Props> = ({ color }) => {
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="m"
        className="mb-l"
      >
        <div
          className={styles.line}
          style={{
            backgroundColor: color,
          }}
        />
        <Flex>
          <MyBarceloLogo width={110} />
        </Flex>

        <div
          className={styles.line}
          style={{
            backgroundColor: color,
          }}
        />
      </Flex>
    </>
  )
}
