import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { TripadvisorIcon } from 'src/ui/components/atoms/Icon/_icons/social/TripadvisorIcon'
import styles from './TripadvisorRatings.module.scss'
import { DotRatings } from './DotRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { FC } from 'react'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  gap: 'small' | 'large'
  reviews: {
    count?: number
    averageRate?: string
  }
  reviewsMode: 'count' | 'average'
}

export const TripadvisorRatings: FC<Props> = ({
  gap,
  reviews,
  reviewsMode,
}) => {
  const hasAverageRate = isDefined(reviews.averageRate)
  const hasReviewsCount = isDefined(reviews.count)
  const showDot = hasAverageRate && hasReviewsCount

  return (
    <Flex
      gap={gap === 'small' ? 'xxs' : 'xs'}
      direction="row"
      alignItems="center"
    >
      <Icon size="l" icon={TripadvisorIcon} className={styles.icon} />
      {showDot && <div className={styles.dot} />}
      {hasAverageRate && reviewsMode === 'average' && (
        <DotRatings ratings={reviews.averageRate} />
      )}
    </Flex>
  )
}
