import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { BComIcon } from 'src/ui/components/atoms/Icon/_icons/general/BComIcon'
import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'

export const HotelPromotionsHeader = () => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Flex alignItems="center" gap="xs">
      <Icon icon={BComIcon} size="l" />
      <Text fontStyle="m-700" color="dark">
        {trans('available-rooms_banner-advantages_title')}
      </Text>
    </Flex>
  )
}
