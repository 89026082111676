import { Fragment } from 'react'
import styles from './Card.module.scss'
import { Header } from './Header'
import { Benefit } from './Benefit'
import { useTrans } from 'src/ui/hooks/useTrans'

export const Card = () => {
  const { trans } = useTrans(['common'])

  const userConfig = {
    color: 'var(--b-color-myBarcelo-start)',
    benefits: [
      {
        title: trans('benefits_no-level-user_bullet-1_title'),
        text: trans('benefits_no-level-user_bullet-1_text'),
      },
      {
        title: trans('benefits_no-level-user_bullet-2_title'),
        text: trans('benefits_no-level-user_bullet-2_text'),
      },
      {
        title: trans('benefits_no-level-user_bullet-4_title'),
        text: trans('benefits_no-level-user_bullet-4_text'),
      },
    ],
  }

  return (
    <div className={styles.card}>
      <Header color={userConfig.color} />

      <ul className={styles.list}>
        {userConfig.benefits.map((benefit, index) => {
          return (
            <Fragment key={index}>
              <Benefit benefit={benefit} />
            </Fragment>
          )
        })}
      </ul>
    </div>
  )
}
