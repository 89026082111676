import { FC } from 'react'

interface Props {
  color: string
}

export const LocationIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M11.9744 21.7639C7.18657 17.0799 5.00062 12.0321 5.00062 9.32592C4.94885 5.4225 8.12314 2.29999 11.9744 2.29999C15.8257 2.29999 19 5.4225 19 9.32592C19 13.9053 14.0561 19.474 11.9744 21.7639"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0775 5.99548C13.7325 5.99548 15.0055 7.26841 15.0055 8.92343C15.0055 10.578 13.6688 11.8509 12.0775 11.8509C10.4867 11.8509 9.15001 10.578 9.15001 8.92343C9.15001 7.33217 10.4229 5.99548 12.0775 5.99548Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default LocationIcon
