import {
  Availability,
  AvailabilityCriteria,
} from 'src/core/Availability/domain/Availability.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { AvailabilityRepository } from 'src/core/Availability/domain/Availability.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'

export const getAvailabilityByGroupCoupon =
  ({ availabilityRepository, analytics }: Dependencies) =>
  async (
    request: AvailabilityCriteria | undefined,
  ): Promise<Availability | undefined> => {
    if (isUndefined(request)) {
      return undefined
    }

    try {
      const availability = await availabilityRepository.all(request)
      analytics.requests.availability(availability)

      return availability
    } catch (error) {
      if (
        error instanceof AvailabilityError &&
        error.type === AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR
      ) {
        const availability = await availabilityRepository.all({
          ...request,
          groupCode: undefined,
        })
        analytics.requests.availability(availability)

        return availability
      }

      throw error
    }
  }

interface Dependencies {
  availabilityRepository: AvailabilityRepository
  analytics: Analytics
}
