import { Time } from 'src/core/Shared/infrastructure/Time'
import {
  AvailabilityRateDTO,
  AvailabilityRelatedDTO,
  AvailabilityStayDTO,
} from './Availability.api.dto'
import { mapPriceDTOWithConverted } from './mapPriceDTOWithConverted'
import {
  first,
  isDefined,
  isUndefined,
  uniqBy,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  AvailabilityRelatedRateMetadata,
  Availability,
} from 'src/core/Availability/domain/Availability.model'
import {
  CancellationPolicy,
  getDefaultCancellation,
  getDefaultCancellationPenalty,
  defaultRateCancellationPenalty,
  defaultRelativeCancellation,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { mapPriceDTO } from 'src/core/Shared/infrastructure/mappers/mapPriceDTO'

export const mapAvailability = (
  stays: AvailabilityStayDTO[],
  hotel: { id: string; name: string },
): Availability => {
  return {
    groupCode: getGroupCode(stays),
    hotel: {
      id: hotel.id,
      name: hotel.name,
    },
    stays: stays.map(({ rooms, details }) => ({
      rooms: rooms.map(room => ({
        id: room.id,
        name: room.details.name,
        occupancy: room.details.occupancy,
        inventory: room.inventory,
        mealplans: room.mealPlans.map(mealplan => ({
          id: mealplan.id,
          name: mealplan.details.name,
          rates: mealplan.rates.map(rate => ({
            id: rate.id,
            name: rate.details.name,
            description: rate.details.description,
            averageNightly: {
              netPrice: mapPriceDTOWithConverted(rate.average.base),
              grossPrice: mapPriceDTOWithConverted(rate.average.total),
              netDiscount: isDefined(rate.average.discount)
                ? mapPriceDTOWithConverted(rate.average.discount.beforeTax)
                : undefined,
              grossDiscount: isDefined(rate.average.discount)
                ? mapPriceDTOWithConverted(rate.average.discount.afterTax)
                : undefined,
            },
            total: {
              netPrice: mapPriceDTOWithConverted(rate.stay.base),
              grossPrice: mapPriceDTOWithConverted(rate.stay.total),
              netDiscount: isDefined(rate.stay.discount)
                ? mapPriceDTOWithConverted(rate.stay.discount.beforeTax)
                : undefined,
              grossDiscount: isDefined(rate.stay.discount)
                ? mapPriceDTOWithConverted(rate.stay.discount.afterTax)
                : undefined,
            },
            nightly: rate.nightly.map(night => ({
              date: Time.fromString(night.date).toDate(),
              base: mapPriceDTOWithConverted(night.base),
              total: mapPriceDTOWithConverted(night.total),
            })),
            nonConvertedTotal: {
              netPrice: mapPriceDTO(rate.stay.base, {
                getConverted: false,
              }),
              grossPrice: mapPriceDTO(rate.stay.total, {
                getConverted: false,
              }),
            },
            cancellation: mapCancellation(rate),
            guarantee: mapGuarantee(rate),
            isMember: rate.details.isMember,
            isMobile: rate.details.isMobile,
            isRelated: false,
            relatedRate: isDefined(rate.related)
              ? mapRelatedRate(rate.related)
              : undefined,
            coupon: first(rate.coupons),
          })),
        })),
      })),
      details: {
        startDate: Time.fromString(details.startDate).toDate(),
        endDate: Time.fromString(details.endDate).toDate(),
        occupancy: details.occupancy,
      },
      mealplanFilters: uniqBy(
        rooms.flatMap(room =>
          room.mealPlans.map(mealplan => ({
            id: mealplan.id,
            name: mealplan.details.name,
          })),
        ),
        'id',
      ),
    })),
  }
}

const getGroupCode = (stays: AvailabilityStayDTO[]): string | undefined => {
  const allGroupCodes = stays.flatMap(stay =>
    stay.rooms.flatMap(room =>
      room.mealPlans.flatMap(mealPlan =>
        mealPlan.rates.map(({ groupCode }) => groupCode).filter(isDefined),
      ),
    ),
  )

  const firstGroupCode = first(allGroupCodes)

  return firstGroupCode
}

const mapRelatedRate = (
  related: AvailabilityRelatedDTO,
): AvailabilityRelatedRateMetadata => {
  return {
    rate: {
      id: related.rate.id,
      isMember: false,
      isMobile: false,
      isRelated: true,
      name: related.rate.details.name,
      description: related.rate.details.description,
      averageNightly: {
        netPrice: mapPriceDTOWithConverted(related.rate.average.base),
        grossPrice: mapPriceDTOWithConverted(related.rate.average.total),
        netDiscount: isDefined(related.rate.average.discount)
          ? mapPriceDTOWithConverted(related.rate.average.discount.beforeTax)
          : undefined,
        grossDiscount: isDefined(related.rate.average.discount)
          ? mapPriceDTOWithConverted(related.rate.average.discount.afterTax)
          : undefined,
      },
      total: {
        netPrice: mapPriceDTOWithConverted(related.rate.stay.base),
        grossPrice: mapPriceDTOWithConverted(related.rate.stay.total),
      },
      nightly: related.rate.nightly.map(night => ({
        date: Time.fromString(night.date).toDate(),
        base: mapPriceDTOWithConverted(night.base),
        total: mapPriceDTOWithConverted(night.total),
      })),
      nonConvertedTotal: {
        netPrice: mapPriceDTO(related.rate.stay.base, {
          getConverted: false,
        }),
        grossPrice: mapPriceDTO(related.rate.stay.total, {
          getConverted: false,
        }),
      },
      cancellation: mapCancellation(related.rate),
      guarantee: mapGuarantee(related.rate),
      coupon: first(related.rate.coupons),
    },
    association: related.association,
    convertedDifference: isDefined(related.convertedDifference)
      ? mapPriceDTO(related.convertedDifference, {
          getConverted: false,
        })
      : { value: 0, currency: related.rate.average.base.currency },
  }
}

export const mapCancellation = (
  rate: AvailabilityRateDTO,
): CancellationPolicy => {
  if (isUndefined(rate.cancellation)) {
    return {
      ...getDefaultCancellation(
        mapPriceDTOWithConverted(rate.average.base).currency,
      ),
    }
  }

  return {
    id: rate.cancellation.id,
    cancellationType: calculateCancellationType(
      rate.cancellation,
      rate.details.policies.cancellation,
    ),
    penalty: rate.cancellation.penalty
      ? mapPriceDTOWithConverted(rate.cancellation.penalty)
      : getDefaultCancellationPenalty(
          mapPriceDTOWithConverted(rate.average.base).currency,
        ),
    calculatedCharge: {
      beforeDeadline: mapPriceDTO(rate.breakdown.cancellationBeforeDeadline, {
        getConverted: true,
      }),
      afterDeadline: mapPriceDTO(rate.breakdown.cancellationAfterDeadline, {
        getConverted: true,
      }),
    },
    applies: rate.cancellation.applies,
    deadline: rate.cancellation.deadline
      ? Time.fromString(rate.cancellation.deadline, { keepTimezone: true })
      : undefined,
    hotelUTC: isDefined(rate.cancellation.deadline)
      ? Time.getUTCOffsetFromISOString(rate.cancellation.deadline)
      : '',
    flexible: rate.details.policies.cancellation?.flexible ?? false,
    relative: rate.details.policies.cancellation
      ? {
          penalty:
            rate.details.policies.cancellation.penalty ??
            defaultRateCancellationPenalty,
          deadline: rate.details.policies.cancellation.deadline,
        }
      : defaultRelativeCancellation,
  }
}

export const calculateCancellationType = (
  cancellation: AvailabilityRateDTO['cancellation'],
  rateCancellation: AvailabilityRateDTO['details']['policies']['cancellation'],
) => {
  if (isUndefined(cancellation) || isUndefined(rateCancellation)) {
    return 'free'
  }

  if (!rateCancellation.flexible) {
    return 'non-refundable'
  }

  if (!cancellation.applies) {
    return 'free'
  }

  return 'policy'
}

export const mapGuarantee = (rate: AvailabilityRateDTO): GuaranteePolicy => {
  return {
    id: rate.guarantee.id,
    type: calculateGuaranteeType(rate),
    deposit: normalizeDeposit(rate),
    pending: normalizePending(rate),
    deadline: Time.fromString(rate.guarantee.deadline).toDate(),
    prepay: rate.details.policies.guarantee.prepay,
    relative: {
      deposit: rate.details.policies.guarantee.deposit,
    },
  }
}

export const calculateGuaranteeType = (
  rate: AvailabilityRateDTO,
): GuaranteePolicy['type'] => {
  if (
    !rate.details.policies.guarantee.prepay ||
    !rate.details.policies.guarantee.deposit
  ) {
    return { key: 'all-hotel' }
  }

  if (normalizePending(rate).value === 0) {
    return { key: 'all-prepay' }
  }

  const depositType = rate.details.policies.guarantee.deposit.type
  const depositValue = rate.details.policies.guarantee.deposit.value

  return {
    key: `partial-${depositType}`,
    value: depositValue,
  }
}

const normalizePending = (rate: AvailabilityRateDTO): Price =>
  isDefined(rate.breakdown.payAtHotel)
    ? mapPriceDTOWithConverted(rate.breakdown.payAtHotel)
    : {
        value: 0,
        currency: mapPriceDTOWithConverted(rate.average.base).currency,
      }

const normalizeDeposit = (rate: AvailabilityRateDTO): Price =>
  isDefined(rate.guarantee.deposit)
    ? mapPriceDTOWithConverted(rate.guarantee.deposit)
    : {
        value: 0,
        currency: mapPriceDTOWithConverted(rate.average.base).currency,
      }
