import { FC } from 'react'
import { MealplansInfoButton } from '../MealplansInfoButton'
import { AvailabilityMealplan } from 'src/core/Availability/domain/Availability.model'
import { Text } from 'src/ui/components'
import styles from './MealplansHeader.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  mealplans: AvailabilityMealplan[]
  title: string
}

export const MealplansHeader: FC<Props> = ({ mealplans, title }) => {
  return (
    <div className={classNames(styles.container, 'touch-security-zone')}>
      <Text as="h4" fontStyle="l-700" color="dark">
        {title}
      </Text>
      <MealplansInfoButton mealplans={mealplans} />
    </div>
  )
}
