import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'
import { getValidRule } from 'src/core/Hotel/domain/CouponValidation.model'

export const getPromotionalValidCoupon =
  ({ hotelRepository }: Dependencies) =>
  async (
    couponId: string,
    hotelId: string,
    checkIn: string,
  ): Promise<PromotionalValidCoupon | undefined> => {
    const result = await hotelRepository.getCoupon(couponId, hotelId)

    if (isUndefined(result)) {
      return undefined
    }

    const validRule = getValidRule(result.rule, checkIn)

    if (isUndefined(validRule)) {
      return undefined
    }

    return {
      id: result.id,
      discount: validRule.discount,
    }
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
