import { FC, useEffect } from 'react'
import styles from './DropdownFullScreen.module.scss'

import { Divider } from 'src/ui/components'
import { Text } from 'src/ui/components'
import {
  AvailabilityRoom,
  getBestPriceForRoom,
} from 'src/core/Availability/domain/Availability.model'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { CheckIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Price } from 'src/ui/components/molecules/Price'
import { classNames } from 'src/ui/utils/classnames'
import { useLockBodyScroll } from 'src/ui/hooks/useLockBodyScroll'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'

interface Props {
  show: boolean
  onSelect: (room: AvailabilityRoom) => void
  onSelectAll: () => void
  onCloseDropdown: () => void
  rooms: AvailabilityRoom[]
  selectedRoomType: AvailabilityRoom | undefined
  className?: string
}

export const DropdownFullScreen: FC<Props> = ({
  show,
  onSelect,
  onSelectAll,
  onCloseDropdown,
  rooms,
  selectedRoomType,
  className,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const { lockBodyScroll, releaseBodyScroll } = useLockBodyScroll()

  useEffect(() => {
    if (show) {
      lockBodyScroll()
    } else {
      releaseBodyScroll()
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid="dropdown"
    >
      <Flex
        className={styles.header}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontStyle="l-700">
          {trans('available-room_room-type-filter_mobile-title')}
        </Text>
        <button
          className="touch-security-zone flex justify-center items-center cursor-pointer"
          onClick={onCloseDropdown}
        >
          <Icon icon={CloseIcon} size="m"></Icon>
        </button>
      </Flex>
      <div className={styles.itemsWrapper}>
        <ul>
          <li>
            <Divider dir="horizontal" />
            <button className={styles.listElement} onClick={onSelectAll}>
              {isUndefined(selectedRoomType) && (
                <Icon
                  size="s"
                  color="fill-neutral-04"
                  icon={CheckIcon}
                  className={classNames(styles.checkIcon, styles.isCenter)}
                  ariaLabel={trans('available-room_room-type-selected-option')}
                />
              )}
              <Text fontStyle="m-500">
                {trans('available-room_room-type-filter_all', {
                  totalRooms: rooms.length,
                })}
              </Text>
            </button>
          </li>
          {rooms.map(room => {
            const roomBestPrice = getBestPriceForRoom(room)
            return (
              <li key={room.id}>
                <Divider dir="horizontal" />
                <button
                  className={styles.listElement}
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    onSelect(room)
                  }}
                >
                  {selectedRoomType?.id === room.id && (
                    <Icon
                      size="s"
                      color="fill-neutral-04"
                      icon={CheckIcon}
                      className={styles.checkIcon}
                      ariaLabel={trans(
                        'available-room_room-type-selected-option',
                      )}
                    />
                  )}
                  <Text as="div" fontStyle="m-500" className={styles.roomName}>
                    {room.name}
                  </Text>
                  <Flex alignItems="center" gap="xxs">
                    <Text fontStyle="s-300">
                      {trans('available-room_room-type-filter_price-from')}
                    </Text>
                    <Price
                      fontStyle="s"
                      fontWeight="regular"
                      netPrice={roomBestPrice.netPrice}
                      grossPrice={roomBestPrice.grossPrice}
                      round="off"
                    />
                  </Flex>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
