import { FC } from 'react'

interface Props {
  color: string
}

export const CalendarIcon: FC<Props> = ({ color }) => (
  <>
    <rect
      x="3"
      y="5.39996"
      width="18"
      height="15.6"
      rx="0.9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10.2H21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.80003 7.20001V3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2001 7.20001V3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default CalendarIcon
