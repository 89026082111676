import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { Pill } from '../Pill'
import { CouponFormType, CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { LevelCoupon } from './LevelCoupon'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { User } from 'src/core/User/domain/User'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { CouponsFormContent } from 'src/ui/views/_layouts/AvailableRooms/CouponsFormContent'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'

interface Props {
  methods: UseFormReturn<CouponsFormValues>
  focused: boolean
  onOpen: () => void
  couponTypeSelected: CouponFormType
  onValidateCoupon: (couponId: string) => Promise<CouponValidationResult>
  user?: User
  onSubmit: () => void
}

export const CouponsPill: FC<Props> = ({
  methods,
  onOpen,
  focused,
  couponTypeSelected,
  user,
  onValidateCoupon,
  onSubmit,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const showLevelCoupon =
    isDefined(user) &&
    isDefined(user.discount) &&
    couponTypeSelected !== CouponFormType.GroupCode

  return (
    <Pill
      hasError={false}
      focused={focused}
      onOpen={onOpen}
      expandedComponent={
        <div>
          <Flex
            alignItems="baseline"
            justifyContent="space-between"
            className="mb-l"
          >
            <Text fontStyle="xl-700" color="dark">
              {trans('coupons-form_discounts')}
            </Text>
            <Text fontStyle="m-300" color="support-success">
              {trans('coupons-form_only-in-my-barcelo')}
            </Text>
          </Flex>

          {showLevelCoupon && <LevelCoupon user={user} />}

          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <CouponsFormContent
                methods={methods}
                onValidateCoupon={onValidateCoupon}
                revalidate={focused}
                keyForValidPromotionalCodeMessage="coupons-input_validation_valid"
                keyForInvalidValidPromotionalCodeMessage="coupons-input_validation_invalid"
              />
            </form>
          </FormProvider>
        </div>
      }
      collapsedComponent={
        <>
          <Text fontStyle="m-300" color="dark">
            {trans('coupons-form_discounts')}
          </Text>
          <Flex>
            <AppliedCouponsText
              couponTypeSelected={couponTypeSelected}
              userDiscount={user?.discount}
            />
            <Icon icon={ChevronIcon} size="l" rotate={90} />
          </Flex>
        </>
      }
    />
  )
}

interface AppliedCouponsTextProps {
  couponTypeSelected: CouponFormType
  userDiscount?: number
}

const AppliedCouponsText: FC<AppliedCouponsTextProps> = ({
  couponTypeSelected,
  userDiscount,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const appliedCouponsTextMap = {
    [CouponFormType.None]: () =>
      isDefined(userDiscount) ? (
        <Text fontStyle="m-700" color="dark">
          {trans('coupons-form_applied-coupons', {
            couponsCount: 1,
          })}
        </Text>
      ) : (
        <Text fontStyle="m-300" color="support-success">
          {trans('coupons-form_only-in-my-barcelo')}
        </Text>
      ),
    [CouponFormType.PromotionalCode]: () => (
      <Text fontStyle="m-700" color="dark">
        {trans('coupons-form_applied-coupons', {
          couponsCount: isDefined(userDiscount) ? 2 : 1,
        })}
      </Text>
    ),
    [CouponFormType.GroupCode]: () => (
      <Text fontStyle="m-700" color="dark">
        {trans('coupons-form_group-code-applied')}
      </Text>
    ),
  }

  return (
    appliedCouponsTextMap[couponTypeSelected]?.() ?? (
      <Text fontStyle="m-300" color="support-success">
        {trans('coupons-form_only-in-my-barcelo')}
      </Text>
    )
  )
}
